import React from 'react';
import styled, { css } from 'styled-components';

// Cache container
const container = document.querySelector('.container');

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
	if (!value) return;

	let width = (value / 12) * 100;
	return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
	if (!value) return;

	let flex = (value / 12) * 100;
	return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;
    height: 100%;
	// Fix for applying proper box-sizing
	html:not(&) {
		box-sizing: border-box;

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}
	}

	body:not(&) {
		font: 16px / 1.618 
	}
`;

// Grid container
const GridContainer = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	// Breakpoint for tablets
	@media (min-width: 576px) {
		max-width: ${remy(540)};
	}

	// Breakpoint for small desktops
	@media (min-width: 768px) {
		max-width: ${remy(720)};
	}

	// Breakpoint for medium desktops
	@media (min-width: 992px) {
		max-width: ${remy(9600)};
	}

	// Breakpoint for large desktops and HD devices
	@media (min-width: 1200px) {
		max-width: ${remy(1140)};
	}
`;

// Grid row
const GridRow = styled.div`
	margin-right: ${remy(-15)};
	margin-left: ${remy(-15)};
	display: flex;
	flex-wrap: wrap;
`;

// Create layout component
class CareersCard extends React.Component {
	flipCard(event) {
		event.currentTarget.classList.toggle('flipped');
	}

	render() {
		return (
			<LayoutWrapper style={{backgroundColor: '#171717', height: '70vh'}}>
				<GridContainer style={{textAlign: 'center'}}>
				
						<h6 className="cutso-mission-header">Careers</h6>
                        <br />
						
                        <p className="text-center" style={{ color: 'white', fontSize: '18px', padding: '8vh', textAlign: 'center' }}>
						Are you brilliant? Drop us a line at <a href="mailto:talent@cutso.co" style={{color: '#827b5a'}}>talent@cutso.co</a>
						
						</p>
				
				</GridContainer>
			</LayoutWrapper>
		);
	}
}

// Render Layout React element into the DOM
export default CareersCard;

import React from "react";
import styled, { css } from "styled-components";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./HowItWorks.css";
// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
  if (!value) return;

  let flex = (value / 12) * 100;
  return `flex: 0 0 ${flex}%;`;
};

// Grid container
const GridContainer = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  // Breakpoint for tablets
  @media (min-width: 576px) {
    max-width: ${remy(540)};
    margin-top: 0px;
  }

  // Breakpoint for small desktops
  @media (min-width: 768px) {
    max-width: ${remy(720)};
  }

  // Breakpoint for medium desktops
  @media (min-width: 992px) {
    max-width: ${remy(9600)};
  }

  // Breakpoint for large desktops and HD devices
  @media (min-width: 1200px) {
    max-width: ${remy(1140)};
    margin-top: 200px;
  }
`;

// Grid row
const GridRow = styled.div`
  margin-right: ${remy(-15)};
  margin-left: ${remy(-15)};
  display: flex;
  flex-wrap: wrap;
`;

// Grid columns
const GridColumn = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};

  // Columns for mobile
  ${({ xs }) => (xs ? getFlex(xs) : "flex: 0 0 100%")};
  ${({ xs }) => (xs ? getWidth(xs) : "width: 100%")};

  // Columns for tablets
  @media (min-width: 576px) {
    ${({ sm }) => sm && getFlex(sm)};
    ${({ sm }) => sm && getWidth(sm)};
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    ${({ md }) => md && getFlex(md)};
    ${({ md }) => md && getWidth(md)};
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    ${({ lg }) => lg && getFlex(lg)};
    ${({ lg }) => lg && getWidth(lg)};
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    ${({ xl }) => xl && getFlex(xl)};
    ${({ xl }) => xl && getWidth(xl)};
  }

  @media (min-width: 2560px) {
    ${({ xxl }) => xxl && getFlex(xxl)};
    ${({ xxl }) => xxl && getWidth(xxl)};
  }
`;

const MainDiv = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    color: #222;
    margin: 0px;
    padding-bottom: 100px;
    text-align: center;
    width: 100%;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    color: #222;
    margin: 0px;
    padding-bottom: 100px;
    text-align: center;
    width: 100%;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    color: #222;
    margin: 0px;
    padding-bottom: 100px;
    text-align: center;
    width: 100%;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    color: #222;
    margin: 0px;
    padding-bottom: 100px;
    text-align: center;
    width: 100%;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    color: #222;
    margin-top: 30px;
    text-align: end;
    justify-content: end;
  }
`;

const MainDiv2 = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    color: #222;
    margin: 0px;
    padding-top: 150px;
    text-align: center;
    width: 100%;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    color: #222;
    margin: 0px;
    padding-top: 150px;
    text-align: center;
    width: 100%;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    color: #222;
    /margin-top: 20px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    color: #222;
    margin-top: 20px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    color: #222;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
  }
`;

const MainDiv3 = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    margin: 0px;
    padding: 0px;
    color: #e1e1e1;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin: 0px;
    padding: 0px;
    color: #e1e1e1;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin: 0px;
    padding: 0px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin: 0px;
    padding: 0px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    margin: 0px;
    padding-left: 60px;
    color: #e1e1e1;
    margin-bottom: 200px;
  }
`;

const Heading = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    font-family: "Avenir Black";
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 35px;
    line-height: 120%;
    flex-direction: row;
    text-align: start;
    justify-content: start;
    margin-bottom: 10px;
    color: #e1e1e1;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-family: "Avenir Black";
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 35px;
    line-height: 120%;
    flex-direction: row;
    text-align: start;
    justify-content: start;
    margin-bottom: 10px;
    color: #e1e1e1;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    flex-direction: "row";
    text-align: end;
    justify-content: end;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 150%;
    color: #e1e1e1;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    flex-direction: "row";
    text-align: end;
    justify-content: end;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 150%;
    color: #e1e1e1;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    padding-left: 60px;
    flex-direction: row;
    text-align: start;
    align-self: start;
    justify-content: start;
    letter-spacing: 7px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 130%;
    font-family: avenir;
    color: #e1e1e1;
    margin-bottom: 50px;
  }
`;

const Heading2 = styled.div`
	// Columns for mobile
	@media screen and (max-width: 576px) {
		flex-direction: row;
		text-align: center;
		justify-content: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		margin-top: 50px;
		color: #E1E1E1;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
    flex-direction: row;
		text-align: center;
		justify-content: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		margin-top: 50px;
		color: #E1E1E1;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		flex-direction: 'row';
		text-align: end;
		justify-content: end;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		color: #E1E1E1;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		flex-direction: 'row';
		text-align: end;
		justify-content: end;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		color: #E1E1E1;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		flex-direction: row;
		text-align: end;
		justify-content: end;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		font-family: avenir;
		margin-left: 50px;
		padding-top: 40px;
		color: #E1E1E1;
		
`;

const Heading3 = styled.div`
	// Columns for mobile
	@media screen and (max-width: 576px) {
    font-family: "Avenir Black";
		font-weight: 500;
		font-size: 22px;
		letter-spacing: 1px;
		text-transform: uppercase;
		justify-content: start;
		flex-direction: row;
		text-align: start;
		line-height: 150%;
		margin-top: 50px;
		color: #E1E1E1;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
    font-family: "Avenir Black";
		font-weight: 500;
		font-size: 22px;
		letter-spacing: 1px;
		text-transform: uppercase;
    flex-direction: row;
		text-align: start;
		justify-content: start;
		line-height: 150%;
		margin-top: 50px;
		color: #E1E1E1;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		flex-direction: 'row';
		text-align: end;
		justify-content: end;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		color: #E1E1E1;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		flex-direction: 'row';
		text-align: end;
		justify-content: end;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 26px;
		line-height: 150%;
		color: #E1E1E1;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		flex-direction: row;
		text-align: start;
		justify-content: start;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 24px;
		line-height: 150%;
		font-family: avenir;
		margin-left: 60px;
		padding-top: 60px;
		color: #E1E1E1;
		margin-bottom: 10px;
		
`;
const Text = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 14px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    color: #222;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    color: #222;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    color: #222;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    color: #222;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;

    font-size: 11.5px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    color: #222;
  }
`;

const Text2 = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 0.1px;
    text-transform: none;
    text-align: start;
    justify-content: start;
    margin-right: 20px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 1px;
    text-transform: none;
    text-align: start;
    justify-content: start;
    margin-right: 20px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 24px;
    font-family: avenir;
    text-align: start;
    justify-content: start;
    margin-right: 20px;
  }
`;

const VisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: none;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: none;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: initial;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: initial;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: initial;
  }

  @media (min-width: 2560px) {
    display: none;
  }
`;

const VisibilityDiv2 = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: initial;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: initial;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: none;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: none;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: none;
  }
  @media (min-width: 2560px) {
    display: none;
  }
`;

const BigScreenVisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: none;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: none;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: none;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: none;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: none;
  }

  @media (min-width: 2560px) {
    display: initial;
  }
`;

const SmallScreenVisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: initial;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: initial;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: initial;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: initial;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: initial;
  }

  @media (min-width: 2560px) {
    display: none;
  }
`;

class HowItWorks extends React.Component {
  render() {
    return (
      <div>
        <SmallScreenVisibilityDiv>
          <div style={{ backgroundColor: "#968a69" }}>
            <GridContainer>
              <VisibilityDiv>
                <GridRow>
                  <GridColumn
                    style={{
                      color: "#999f",
                      backgroundColor: "#222222",
                      marginTop: "-130px",
                      marginLeft: "-80px",
                      height: "140vh"
                    }}
                    xs="12"
                    sm="12"
                    md="8"
                    lg="8"
                    xl="8"
                  >
                    <MainDiv2>
                      <Heading3>OUR PROMISE </Heading3>

                      <Heading>Ethical Transparency</Heading>

                      <img
                        style={{
                          objectFit: "contain",
                          textAlign: "start",
                          width: "600px",
                          maxWidth: "100%"
                        }}
                        src="/Our-promise-image.jpg"
                      />
                      <div
                        style={{
                          textAlign: "start",
                          paddingLeft: "58px"
                        }}
                      >
                        <Button
                          style={{
                            marginTop: "35px",
                            marginBottom: "0px",
                            width: "250px",
                            backgroundColor: "#222222",
                            letterSpacing: "3px",
                            fontWeight: "500",
                            fontSize: "12px",
                            height: "45px",
                            borderRadius: "0px",
                            color: "#E1E1E1",
                            border: "1px solid #E1E1E1"
                          }}
                          size="lg"
                          color="dark"
                        >
                          <Link className="btn-text" to="/about">
                            FIND OUT MORE
                          </Link>
                        </Button>
                      </div>
                    </MainDiv2>
                  </GridColumn>

                  <GridColumn xs="12" sm="12" md="4" lg="4" xl="4">
                    <MainDiv>
                      <Heading2>HOW IT WORKS </Heading2>
                      <MainDiv3>
                        {/* completed */}
                        <div className="step completed">
                          <Text2>01 </Text2>
                          <div className="v-stepper">
                            <div className="circle1" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Design</Text2>
                            <Text>
                              We release five new curated designs every week.{" "}
                            </Text>
                          </div>
                        </div>
                        {/* active */}
                        <div className="step completed">
                          <Text2>02 </Text2>
                          <div className="v-stepper">
                            <div className="circle2" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Vote</Text2>
                            <Text>
                              The Cutso community vote for their favourite.
                            </Text>
                          </div>
                        </div>

                        {/* regular */}
                        <div className="step completed">
                          <Text2>03 </Text2>
                          <div className="v-stepper">
                            <div className="circle3" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Drop</Text2>
                            <Text>
                              The top designs are released as limited time
                              drops.
                            </Text>
                          </div>
                        </div>

                        <div className="step completed">
                          <Text2>04 </Text2>
                          <div className="v-stepper">
                            <div className="circle4" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Back it</Text2>
                            <Text>
                              The more people who "Back" the design, the cheaper
                              the shirt gets for everyone.
                            </Text>
                          </div>
                        </div>

                        <div className="step completed">
                          <Text2>05 </Text2>
                          <div className="v-stepper">
                            <div className="circle5" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Make</Text2>
                            <Text>
                              Adults earning a living wage cut & sew digitally
                              printed seaweed fabric into Italian made button
                              down sustainable shirts.
                            </Text>
                          </div>
                        </div>
                      </MainDiv3>
                    </MainDiv>
                  </GridColumn>
                </GridRow>
              </VisibilityDiv>
              <VisibilityDiv2>
                <GridRow>
                  <GridColumn
                    style={{ backgroundColor: "#222222" }}
                    xs="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <MainDiv2>
                      <Heading3>OUR PROMISE </Heading3>

                      <Heading>Ethical Transparency</Heading>

                      <img
                        style={{
                          objectFit: "contain",
                          textAlign: "start",
                          maxWidth: "100%"
                        }}
                        src="/Our-promise-image.jpg"
                      />
                      <div
                        style={{
                          textAlign: "start",
                          paddingLeft: "58px"
                        }}
                      >
                        <Button
                          style={{
                            marginTop: "50px",
                            marginBottom: "60px",
                            width: "250px",
                            backgroundColor: "#222222",
                            letterSpacing: "3px",
                            fontWeight: "500",
                            fontSize: "12px",
                            height: "45px",
                            borderRadius: "0px",
                            color: "#E1E1E1",
                            border: "1px solid #E1E1E1"
                          }}
                          size="lg"
                          color="dark"
                        >
                          <Link className="btn-text" to="/about">
                            FIND OUT MORE
                          </Link>
                        </Button>
                      </div>
                    </MainDiv2>
                  </GridColumn>
                  <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
                    <MainDiv>
                      <Heading2>HOW IT WORKS </Heading2>
                      <MainDiv3>
                        {/* completed */}
                        <div className="step completed">
                          <Text2>01 </Text2>
                          <div className="v-stepper">
                            <div className="circle1" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Design</Text2>
                            <Text>
                              We release five new curated designs every week.
                            </Text>
                          </div>
                        </div>
                        {/* active */}
                        <div className="step completed">
                          <Text2>02 </Text2>
                          <div className="v-stepper">
                            <div className="circle2" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Vote</Text2>
                            <Text>
                              The Cutso community vote for their favourite.
                            </Text>
                          </div>
                        </div>

                        {/* regular */}
                        <div className="step completed">
                          <Text2>03 </Text2>
                          <div className="v-stepper">
                            <div className="circle3" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Drop</Text2>
                            <Text>
                              The top designs are released as limited time
                              drops.
                            </Text>
                          </div>
                        </div>

                        <div className="step completed">
                          <Text2>04 </Text2>
                          <div className="v-stepper">
                            <div className="circle4" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Back it</Text2>
                            <Text>
                              The more people who "Back" the design, the cheaper
                              the shirt gets for everyone.
                            </Text>
                          </div>
                        </div>

                        <div className="step completed">
                          <Text2>05 </Text2>
                          <div className="v-stepper">
                            <div className="circle5" />
                            <div className="line" />
                          </div>
                          <div className="content">
                            <Text2 className="BourBodoni">Make</Text2>
                            <Text>
                              Adults earning a living wage cut & sew digitally
                              printed seaweed fabric into Italian made button
                              down sustainable shirts.
                            </Text>
                          </div>
                        </div>
                      </MainDiv3>
                    </MainDiv>
                  </GridColumn>
                </GridRow>
              </VisibilityDiv2>
            </GridContainer>
          </div>
        </SmallScreenVisibilityDiv>

        <BigScreenVisibilityDiv>
          <div
            style={{
              background: `linear-gradient(to right, #222222 50%, #968a69 50%)`,
              width: "100%"
            }}
          >
            <GridContainer>
              <GridRow>
                <GridColumn
                  xxl="8"
                  style={{
                    color: "#999f",
                    backgroundColor: "#222222"
                    /*marginTop: '-130px',
									marginLeft: '-80px',*/
                  }}
                >
                  <MainDiv2>
                    <Heading3>OUR PROMISE </Heading3>

                    <Heading>Ethical Transparency</Heading>

                    <img
                      style={{
                        objectFit: "contain",
                        textAlign: "start",
                        width: "600px",
                        maxWidth: "100%"
                      }}
                      src="/Our-promise-image.jpg"
                    />
                    <div
                      style={{
                        textAlign: "start",
                        paddingLeft: "58px"
                      }}
                    >
                      <Button
                        style={{
                          marginTop: "40px",
                          marginBottom: "0px",
                          width: "250px",
                          backgroundColor: "#222222",
                          letterSpacing: "3px",
                          fontWeight: "500",
                          fontSize: "12px",
                          height: "45px",
                          borderRadius: "0px",
                          color: "#E1E1E1",
                          border: "1px solid #E1E1E1"
                        }}
                        size="lg"
                        color="dark"
                      >
                        <Link className="btn-text" to="/about">
                          FIND OUT MORE
                        </Link>
                      </Button>
                    </div>
                  </MainDiv2>
                </GridColumn>

                <GridColumn xxl="4">
                  <MainDiv>
                    <Heading2>HOW IT WORKS </Heading2>
                    <MainDiv3>
                      {/* completed */}
                      <div className="step completed">
                        <Text2>01 </Text2>
                        <div className="v-stepper">
                          <div className="circle1" />
                          <div className="line" />
                        </div>
                        <div className="content">
                          <Text2 className="BourBodoni">Design</Text2>
                          <Text>
                            We release five new curated designs every week.{" "}
                          </Text>
                        </div>
                      </div>
                      {/* active */}
                      <div className="step completed">
                        <Text2>02 </Text2>
                        <div className="v-stepper">
                          <div className="circle2" />
                          <div className="line" />
                        </div>
                        <div className="content">
                          <Text2 className="BourBodoni">Vote</Text2>
                          <Text>
                            The Cutso community vote for their favourite.
                          </Text>
                        </div>
                      </div>

                      {/* regular */}
                      <div className="step completed">
                        <Text2>03 </Text2>
                        <div className="v-stepper">
                          <div className="circle3" />
                          <div className="line" />
                        </div>
                        <div className="content">
                          <Text2 className="BourBodoni">Drop</Text2>
                          <Text>
                            The top designs are released as limited time drops.
                          </Text>
                        </div>
                      </div>

                      <div className="step completed">
                        <Text2>04 </Text2>
                        <div className="v-stepper">
                          <div className="circle4" />
                          <div className="line" />
                        </div>
                        <div className="content">
                          <Text2 className="BourBodoni">Back it</Text2>
                          <Text>
                            The more people who "Back" the design, the cheaper
                            the shirt gets for everyone.
                          </Text>
                        </div>
                      </div>

                      <div className="step completed">
                        <Text2>05 </Text2>
                        <div className="v-stepper">
                          <div className="circle5" />
                          <div className="line" />
                        </div>
                        <div className="content">
                          <Text2 className="BourBodoni">Make</Text2>
                          <Text>
                            Adults earning a living wage cut & sew digitally
                            printed seaweed fabric into Italian made button down
                            sustainable shirts.
                          </Text>
                        </div>
                      </div>
                    </MainDiv3>
                  </MainDiv>
                </GridColumn>
              </GridRow>
            </GridContainer>
          </div>
        </BigScreenVisibilityDiv>
      </div>
    );
  }
}

// Render Layout React element into the DOM
export default HowItWorks;

import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./fonts/fonts.css";
import "./index.scss";

WebFont.load({
  google: {
    families: ["Raleway:300"],
    text: "0123456789:"
  },
  custom: {
    families: ["Avenir Black", "Bauer Bodoni:i4,i7"]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

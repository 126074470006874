import React from "react";
import styled, { css } from "styled-components";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import IconDesktopButton from "../../components/IconDesktopButton";
import VoteCarousel from "./VoteCarousel";
import { SSL_OP_TLS_ROLLBACK_BUG } from "constants";

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
  if (!value) return;

  let flex = (value / 12) * 100;
  return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  // Fix for applying proper box-sizing
  html:not(&) {
    box-sizing: border-box;

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
  }

  body:not(&) {
    font: 16px / 1.618;
  }
`;

// Grid container
const GridContainer = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  // Breakpoint for tablets
  @media (min-width: 576px) {
    max-width: ${remy(540)};
  }

  // Breakpoint for small desktops
  @media (min-width: 768px) {
    max-width: ${remy(720)};
  }

  // Breakpoint for medium desktops
  @media (min-width: 992px) {
    max-width: ${remy(9600)};
  }

  // Breakpoint for large desktops and HD devices
  @media (min-width: 1200px) {
    max-width: ${remy(1140)};
  }
`;

// Grid row
const GridRow = styled.div`
  margin-right: ${remy(-15)};
  margin-left: ${remy(-15)};
  display: flex;
  flex-wrap: wrap;
`;

// Grid columns
const GridColumn = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};

  // Columns for mobile
  ${({ xs }) => (xs ? getFlex(xs) : "flex: 0 0 100%")};
  ${({ xs }) => (xs ? getWidth(xs) : "width: 100%")};

  // Columns for tablets
  @media (min-width: 576px) {
    ${({ sm }) => sm && getFlex(sm)};
    ${({ sm }) => sm && getWidth(sm)};
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    ${({ md }) => md && getFlex(md)};
    ${({ md }) => md && getWidth(md)};
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    ${({ lg }) => lg && getFlex(lg)};
    ${({ lg }) => lg && getWidth(lg)};
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    ${({ xl }) => xl && getFlex(xl)};
    ${({ xl }) => xl && getWidth(xl)};
  }
`;

const MainDiv = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    color: #222;
    margin: 0px;
    padding: 0px;
    text-align: center;
    width: 100%;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    color: #222;
    text-align: center;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    color: #222;
    /margin-top: 20px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    color: #222;
    margin-top: 20px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    color: #222;
    margin-top: 30px;
    text-align: end;
    justify-content: end;
  }
`;

const MainDiv2 = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    color: #222;
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    width: 100%;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    color: #222;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    color: #222;
    /margin-top: 20px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    color: #222;
    margin-top: 20px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    color: #222;
    margin-top: 30px;
    text-align: center;
    justify-content: center;
  }
`;

const Heading = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    font-family: "Avenir Black";
    font-weight: 900;
    font-size: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 120%;
    flex-direction: row;
    text-align: end;
    justify-content: end;
    margin-bottom: 10px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-family: "Avenir Black";
    font-weight: 900;
    font-size: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 120%;
    flex-direction: row;
    text-align: end;
    justify-content: end;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    flex-direction: row;
    text-align: end;
    justify-content: end;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 120%;
    font-family: avenir;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    flex-direction: row;
    text-align: end;
    justify-content: end;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 120%;
    font-family: avenir;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    text-align: end;
    justify-content: end;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 64px;
    line-height: 120%;
    font-family: avenir;
  }
`;

const Heading2 = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    font-family: "Avenir Black";
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    line-height: 150%;
    margin-top: 50px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-family: "Avenir Black";
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 150%;
    margin-top: 50px;
    margin-bottom: -100px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    flex-direction: "row";
    text-align: "end";
    align-self: "flex-end";
    justify-content: "flex-end";
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    font-family: avenir;
    line-height: 150%;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    flex-direction: "row";
    text-align: "end";
    align-self: "flex-end";
    justify-content: "flex-end";
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: avenir;
    line-height: 150%;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    text-align: center;
    align-self: center;
    justify-content: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    font-family: avenir;
    margin-left: 0px;
    margin-top: 38px;
    margin-bottom: -65px;
  }
`;
const Text = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    font-family: "Avenir Black";
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-family: "Avenir Black";
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    letter-spacing: 0.25px;
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    font-family: avenir;
    margin-top: 40px;
  }
`;

const VisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: none;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: none;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: initial;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: initial;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: initial;
  }

  @media (min-width: 2560px) {
    display: none;
  }
`;

const VisibilityDiv2 = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: initial;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: initial;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: none;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: none;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: none;
  }

  @media (min-width: 2560px) {
    display: none;
  }
`;

const BigScreenVisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: none;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: none;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: none;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: none;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: none;
  }

  @media (min-width: 2560px) {
    display: initial;
  }
`;

const SmallScreenVisibilityDiv = styled.div`
  // Columns for mobile
  @media (max-width: 576px) {
    display: initial;
  }

  // Columns for tablets
  @media (min-width: 576px) {
    display: initial;
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    display: initial;
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    display: initial;
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    display: initial;
  }

  @media (min-width: 2560px) {
    display: none;
  }
`;

class VoteSection extends React.Component {
  render() {
    return (
      <GridContainer style={{ marginTop: "80px" }}>
        <VisibilityDiv>
          <GridRow>
            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv2>
                <Heading2>OUR LATEST DESIGNS</Heading2>
                <VoteCarousel
                  afterChage={this.props.afterChage}
                  beforeChange={this.props.beforeChange}
                />
              </MainDiv2>
            </GridColumn>

            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv>
                <Heading>YOUR VOTES YOUR WAY </Heading>
                <Text>
                  What if, instead of guessing what we think you should wear,
                  you got to vote for which design of shirt you want made?{" "}
                  <br />
                  <br />
                  And instead of making so many shirts that we have to burn what
                  we can't sell, we only make small batches of what's wanted.
                  <br />
                  <br />
                  By helping determine demand before producing any shirts,
                  you’re actively helping eliminate the waste created by unsold
                  stock, and supporting the cleanest way to produce and consume
                  clothes.
                </Text>
                <Link className="btn-text" to="/vote">
                  <Button
                    style={{
                      marginTop: "30px",
                      marginBottom: "80px",
                      width: "250px",
                      backgroundColor: `rgb(34, 34, 34)`,
                      letterSpacing: "3px",
                      fontWeight: "500",
                      color: `rgb(255, 255, 255)`,
                      fontSize: "12px",
                      height: "45px",
                      borderRadius: "0px"
                    }}
                    size="lg"
                    color="dark"
                  >
                    VOTE NOW
                  </Button>
                </Link>
              </MainDiv>
            </GridColumn>
          </GridRow>
        </VisibilityDiv>
        <VisibilityDiv2>
          <GridRow>
            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv>
                <Heading>YOUR VOTES YOUR WAY </Heading>
                <Text>
                  What if, instead of guessing what we think you should wear,
                  you got to vote for which design of shirt you want made?{" "}
                  <br />
                  <br />
                  And instead of making so many shirts that we have to burn what
                  we can't sell, we only make small batches of what's wanted.
                  <br />
                  <br />
                  By helping determine demand before producing any garments,
                  you’re actively helping eliminate the waste created by unsold
                  stock, and supporting the cleanest way to produce and consume
                  clothes.
                </Text>
                <Button
                  style={{
                    marginTop: "30px",
                    marginBottom: "80px",
                    width: "250px",
                    backgroundColor: `rgb(34, 34, 34)`,
                    letterSpacing: "3px",
                    fontWeight: "500",
                    color: `rgb(255, 255, 255)`,
                    fontSize: "12px",
                    height: "45px",
                    borderRadius: "0px"
                  }}
                  size="lg"
                  color="dark"
                >
                  <Link className="btn-text" to="/vote">
                    VOTE NOW
                  </Link>
                </Button>
              </MainDiv>
            </GridColumn>
            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv2>
                <Heading2>OUR LATEST DESIGNS</Heading2>
                <VoteCarousel
                  afterChage={this.props.afterChage}
                  beforeChange={this.props.beforeChange}
                />
              </MainDiv2>
            </GridColumn>
          </GridRow>
        </VisibilityDiv2>
        <BigScreenVisibilityDiv>
          <GridRow style={{ marginBottom: "-800px" }}>
            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv2>
                <Heading2>OUR LATEST DESIGNS</Heading2>
                <VoteCarousel
                  afterChage={this.props.afterChage}
                  beforeChange={this.props.beforeChange}
                />
              </MainDiv2>
            </GridColumn>

            <GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
              <MainDiv>
                <Heading>YOUR VOTES YOUR WAY </Heading>
                <Text>
                  What if, instead of guessing what we think you should wear,
                  you got to vote for which design of shirt you want made?{" "}
                  <br />
                  <br />
                  And instead of making so many shirts that we have to burn what
                  we can't sell, we only make small batches of what's wanted.
                  <br />
                  <br />
                  By helping determine demand before producing any shirts,
                  you’re actively helping eliminate the waste created by unsold
                  stock, and supporting the cleanest way to produce and consume
                  clothes.
                </Text>
                <Link className="btn-text" to="/vote">
                  <Button
                    style={{
                      marginTop: "40px",
                      marginBottom: "80px",
                      width: "250px",
                      backgroundColor: "#222222",
                      letterSpacing: "3px",
                      fontWeight: "500",
                      color: "#fff",
                      fontSize: "12px",
                      height: "45px",
                      borderRadius: "0px"
                    }}
                    size="lg"
                    color="dark"
                  >
                    VOTE NOW
                  </Button>
                </Link>
              </MainDiv>
            </GridColumn>
          </GridRow>
        </BigScreenVisibilityDiv>
      </GridContainer>
    );
  }
}

// Render Layout React element into the DOM
export default VoteSection;

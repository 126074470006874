import React from "react";
import {
  Button,
  Container,
  Card as ReactstrapCard,
  CardTitle,
  Modal,
  ModalBody
} from "reactstrap";
import { gql } from "apollo-boost";
import Swipeable from "react-swipy";
import ReactImageMagnify from "react-image-magnify";

import client, { upVote, downVote } from "../graphql.js";
import { shuffle } from "../utils.js";
import IconButton from "../components/IconButton.js";
import LargeImageModal from "../components/leaderboard/LargeImageModal.js";
import Navigation from "../Home/Navigation/Navigation.js";
import Footer from "../Home/Footer/Footer.js";

class Vote extends React.Component {
  render() {
    // Get the height of the Nav Bar, so we can position the content below it
    const navBar = document.querySelector("header");
    const paddingTop = navBar
      ? (parseInt(navBar.clientHeight) + 10).toString() + "px"
      : "78px";

    return (
      <>
        <Navigation />
        <Container
          className="mt-0 mb-0"
          style={{
            paddingTop: paddingTop,
            height: `calc(100vh - ${paddingTop})`
          }}
        >
          <CardDeck />
        </Container>
        <h1 className="text-center text-dark mt-3 d-lg-none">Swipe to Vote</h1>
        <Footer />
      </>
    );
  }
}

class CardDeck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      designs: [],
      loading: true
    };

    this.handleRemoveCard = this.handleRemoveCard.bind(this);
  }

  render() {
    const { className } = this.props;
    const { cards, loading } = this.state;

    if (!loading && !cards.length) {
      // The user has finished voting
      return (
        <Modal isOpen centered>
          <ModalBody className="text-center">
            <p>
              Thanks for being awesome! You've voted on all the designs. Check
              out our leaderboard to see what might drop next!
            </p>
            <Button href="/leaderboard" color="dark">
              Go to Leaderboard
            </Button>
          </ModalBody>
        </Modal>
      );
    }

    return (
      <>
        {cards.map((design, idx) => {
          return (
            <Card
              key={idx}
              style={{ zIndex: -idx }}
              design={design}
              handleRemoveCard={this.handleRemoveCard}
              className={className}
            />
          );
        })}
      </>
    );
  }

  handleRemoveCard() {
    // Remove top card and add another to the bottom

    this.setState(state => {
      var cards = state.cards;
      var designs = state.designs;
      var next = designs.shift();

      cards.shift();
      if (next) {
        cards.push(next);
      }

      return {
        cards: cards,
        designs: designs
      };
    });
  }

  componentDidMount() {
    // Fetch designs and update state

    const query = gql`
      {
        designs(first: 10, sort: UPVOTES_DESC) {
          edges {
            node {
              id
              name
              image
            }
          }
        }
      }
    `;

    client.query({ query: query }).then(result => {
      if (!result.loading && !result.error) {
        var designs = shuffle(result.data.designs.edges.map(item => item.node));
        const cards = designs.slice(0, 10);
        designs = designs.slice(10);

        this.setState({
          cards: cards,
          designs: designs,
          loading: false
        });
      }
    });
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleAfterSwipe = this.handleAfterSwipe.bind(this);
    this.getButtons = this.getButtons.bind(this);
  }

  render() {
    const { design } = this.props;

    // TODO: It would be great to figure out one set of styles that works for both mobile and
    // desktop here
    const style = {
      ...{
        position: "absolute",

        // Horizontal centering
        left: "0",
        right: "0",
        margin: "auto",

        // Sizing
        maxWidth: "80%"
      },
      ...this.props.style
    };

    const mobileStyle = {
      ...style,
      ...{
        // Vertical centering
        top: "50%",
        transform: "translateY(-50%)"
      }
    };

    const desktopStyle = {
      ...style,
      ...{
        maxWidth: "25%"
      }
    };

    return (
      <>
        <div className="d-xl-none" style={mobileStyle}>
          <Swipeable
            buttons={this.getButtons}
            min="100000"
            onSwipe={this.handleSwipe}
            onAfterSwipe={this.handleAfterSwipe}
          >
            <ReactstrapCard color="light">
              <CardTitle>
                <h1 className="text-capitalize text-center text-dark">
                  {design.name}
                </h1>
              </CardTitle>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    isFluidWidth: true,
                    src: `${design.image}/${design.name}-1500-1580-F8F6F0.jpg`,
                    alt: `Small Picture of ${design.name} Shirt Design`
                  },
                  largeImage: {
                    src: `${design.image}/${design.name}-2200-2317-F8F6F0.jpg`,
                    alt: `Large Picture of ${design.name} Shirt Design`,
                    width: 1200,
                    height: 1800
                  },

                  isHintEnabled: true,
                  pressDuration: 300,
                  shouldHideHintAfterFirstActivation: false,
                  enlargedImagePosition: "over"
                }}
              />
            </ReactstrapCard>
          </Swipeable>
        </div>
        <div className="d-none d-xl-block" style={desktopStyle}>
          <Swipeable
            buttons={this.getButtons}
            min="100000"
            onSwipe={this.handleSwipe}
            onAfterSwipe={this.handleAfterSwipe}
          >
            <ReactstrapCard color="light">
              <CardTitle>
                <h1 className="text-capitalize text-center text-dark">
                  {design.name}
                </h1>
              </CardTitle>
              <LargeImageModal
                img={`${design.image}/${design.name}-2200-2317-F8F6F0.jpg`}
                alt={design.name}
              >
                <img
                  style={{ maxWidth: "100%" }}
                  width="430"
                  src={`${design.image}/${design.name}-1500-1580-F8F6F0.jpg`}
                  alt={design.name}
                />
              </LargeImageModal>
            </ReactstrapCard>
          </Swipeable>
        </div>
      </>
    );
  }

  handleSwipe(direction) {
    // Cast vote on swipe
    const { design } = this.props;
    // TODO: Define these somewhere else. They don't need to be defined every time we swipe.
    // TODO: Should we handle a failure here? Retry? Allow the user to reswipe?
    client.mutate({
      mutation: direction === "right" ? upVote : downVote,
      variables: { input: { id: design.id } }
    });
  }

  handleAfterSwipe() {
    // Remove card after swipe
    const { handleRemoveCard } = this.props;
    handleRemoveCard();
  }

  getButtons({ right, left }) {
    const actionsStyles = {
      display: "flex",
      justifyContent: "space-between"
    };

    const IconRightStyles = {
      width: "60px",
      height: "60px",
      transform: "rotate(0deg)",
      marginTop: "3px"
    };

    const IconLeftStyles = {
      width: "60px",
      height: "60px",
      transform: "rotate(0deg)",
      marginTop: "4px"
    };

    return (
      <div className="d-none d-md-block">
        <div style={actionsStyles}>
          <IconButton onClick={left}>
            <i className=" fa-rotate-180">
              <img
                style={{ maxWidth: "100%" }}
                className="up-rotate-icon"
                style={IconLeftStyles}
                src="/tdown.png"
                alt="Thumbs down"
              />
            </i>
          </IconButton>
          <IconButton onClick={right}>
            <i>
              <img
                style={{ maxWidth: "100%" }}
                className="up-icon"
                style={IconRightStyles}
                src="/tup.png"
                alt="Thumbs up"
              />
            </i>
          </IconButton>
        </div>
      </div>
    );
  }
}
export default Vote;

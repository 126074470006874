import React from "react";
import { gql } from "apollo-boost";
import client from "../graphql.js";
import {
  Button,
  Container,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap";

import Navigation from "../Home/Navigation/Navigation.js";
import FundingComponent from "../components/FundingBar.js";
import Status404NotFound from "../components/Status404NotFound.js";
import { FacebookShare, TwitterShare } from "../components/Social.js";
import {
  client as shopify,
  getVariant,
  getOrCreateCheckout,
  setCheckout
} from "../shopify.js";

// TODO: Look into this on smaller screens
// TODO: Look into a PageWithNavBar component?
//    Maybe I can pass classNames and style into the container component?

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;
    this.state = {
      design: null,
      size: "Select Size",
      quantity: 1,
      loading: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBackIt = this.handleBackIt.bind(this);
    this.renderProduct = this.renderProduct.bind(this);
    this.renderLoader = this.renderLoader.bind(this);
  }

  render() {
    const { design, loading } = this.state;

    if (!loading && (design === null || !design.orderable)) {
      return <Status404NotFound />;
    }

    // Get the height of the Nav Bar, so we can position the content below it
    const navBar = document.querySelector("header");
    this.paddingTop = navBar
      ? (parseInt(navBar.clientHeight) + 10).toString() + "px"
      : "78px";

    return (
      <div>
        <Navigation />
        <Container
          className="mt-0 mb-0 vh-100"
          style={{ paddingTop: this.paddingTop }}
        >
          {loading ? this.renderLoader() : this.renderProduct()}
        </Container>
      </div>
    );
  }

  renderProduct() {
    const { design, size } = this.state;

    return (
      <Row>
        <Col>
          <img
            className="img-fluid d-block"
            src={`${design.image}/${design.name}-600-632.jpg`}
            alt={`${design.name} shirt design`}
          />
          <Row className="justify-content-center text-dark">
            <Col xs="6" className="d-flex justify-content-center">
              <div>
                <FacebookShare textOrUrl={window.location.href} /> |{" "}
                <TwitterShare
                  textOrUrl={`Check out this awesome shirt design at ${
                    window.location.href
                  }`}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <div
          className="col overflow-auto"
          style={{ height: `calc(100vh - ${this.paddingTop})` }}
        >
          <FundingComponent design={design} />
          <Row>
            <Col>
              <Input
                className="btn w-100 border"
                type="select"
                value={size}
                onChange={this.handleChange}
              >
                <option value={null}>Select Size</option>
                <option value="XS">XS</option>
                <option value="SM">SM</option>
                <option value="MD">MD</option>
                <option value="LG">LG</option>
                <option value="XL">XL</option>
              </Input>
              <SizeGuideModal text="SIZE GUIDE" />
            </Col>
            <Col>
              <Button
                className="w-100 bg-dark"
                onClick={this.handleBackIt}
                disabled={size === "Select Size"}
              >
                Back It
              </Button>
            </Col>
          </Row>
          <div className="mt-3 text-dark">
            <h2>PRODUCT DETAILS</h2>
            <p>{design.description}</p>
            <h2>WHAT MAKES A CUTSO SHIRT</h2>
            <p>
              For every shirt sold over the minimum order quantity, the price
              goes down for everyone. Where most brands would keep that savings
              in their profit margin, we're passing it on to you.
              <br />
              For every shirt sold over the minimum order quantity, the price
              goes down for everyone. Where most brands would keep that savings
              in their profit margin, we're passing it on to you.
              <br />
              For every shirt sold over the minimum order quantity, the price
              goes down for everyone. Where most brands would keep that savings
              in their profit margin, we're passing it on to you.
            </p>
            <h2>EXPECTED DELIVERY</h2>
            <p>March 2020</p>
          </div>
        </div>
      </Row>
    );
  }

  renderLoader() {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner color="dark" />
      </div>
    );
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ size: event.target.value });
  }

  handleBackIt(event) {
    // Fetch variant and checkout, then add the variant as a line item to the checkout

    event.preventDefault();

    const {
      design: { name },
      size,
      quantity
    } = this.state;
    Promise.all([getVariant(name, size), getOrCreateCheckout()])
      .then(([variant, checkout]) => {
        const lineItem = { variantId: variant.id, quantity: quantity };
        shopify.checkout
          .addLineItems(checkout.id, [lineItem])
          .then(checkout => {
            setCheckout(checkout);
            window.location.href = "/test/";
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  }

  // TODO: Maybe we should have a separate table for orderable products?
  //    Maybe instead I can just use the shopify API - That will only have available products
  // TODO: Maybe this page should be a details page and ordering tools would show up if available
  componentDidMount() {
    // Fetch designs and update state

    const { id } = this.props.match.params;
    const query = gql`
      query fetchDesign($id: ID!) {
        design(id: $id) {
          id
          name
          description
          image
          orderable
          orders
          ordersMax
          ordersGoal
          initialPrice
          minimumPrice
          currentPrice
          dropEnd
        }
      }
    `;

    client
      .query({ query: query, variables: { id: id } })
      .then(result => {
        if (result.loading || result.error) {
          return;
        }

        const { design } = result.data;

        return this.setState({
          design: design,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        return this.setState({ loading: false });
      });
  }
}

// TODO: Do I already have a modal component?
//  Can I make one children for the activator element?
class SizeGuideModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  render() {
    // TODO: Figure out another way besides style cursor:pointer?
    return (
      <div className="text-dark">
        <div
          className="text-right"
          style={{ cursor: "pointer" }}
          onClick={this.toggle}
        >
          {this.props.text}
        </div>
        <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Sizing Guide</ModalHeader>
          <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </ModalBody>
        </Modal>
      </div>
    );
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
}

export default ProductPage;

import React from 'react';
//import Navigation from '../components/Navigation';
import { Button } from 'reactstrap';
import Modal from 'react-awesome-modal';
import { Link } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';
import Footer from '../Home/Footer/Footer';
import JoinExperimentModal from '../components/JoinExperimentModal.js';
import AboutCard from '../components/AboutCard';
import AboutCardTwo from '../components/AboutCardTwo';
import Navigation from '../Home/Navigation/Navigation';

export default class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true,
			input: false,
			visible: false,
		};
	}
	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));
	};

	modal = () => {
		this.setState(prevState => ({
			visible: !prevState.visible,
		}));
	};

	render() {
		return (
			<div class="app">
			<Navigation original={true} />
				{/*<Navigation modal={this.modal} state="about" toggle={this.toggle} color="#171717" />*/}
				<AboutCardTwo  />

				<JoinExperimentModal
					handleJoinExperimentModal={this.modal}
					close={this.modal}
					join_experiment_modal={this.state.visible}
				/>

				<section>
					<Modal
						visible={this.state.visible_signup}
						width="700"
						height="400"
						effect="fadeInUp"
						onClickAway={() => this.modal()}
					>
						<div className="modal-div">
							<h1 className="modal-title">Join Our Design Community</h1>
							<p className="modal-text">
								Are you creative? Do you want your designs on our awesome, sustainable shirts
							</p>
							<p className="modal-text">
								Email us an image of the design, the title, and a little inspiration.
							</p>

							<Button className="vote-btn" size="lg" color="dark">
								<a href="mailto:designs@cutso.co"> Submit a Design </a>
							</Button>
						</div>
					</Modal>
				</section>

				<section class="wrapper">
					<div class="container-fostrap">
						<div>
							<h1 className="heading">Our People</h1>
						</div>

						<AboutCard />
					</div>
				</section>
				<div style={{ marginTop: '100px' }} />
				<hr />
				<Footer />
			</div>
		);
	}
}

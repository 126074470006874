import React from "react";
import Modal from "reactstrap";

import LargeImageModal from "./LargeImageModal.js";
import "./leaderboard.scss";

class LeaderBoardItem extends React.Component {
  render() {
    const srcBase = `${this.props.design.image}/${this.props.design.name}`;
    var designerParagraph = (
      <p className="text-center" style={{ fontSize: "28px" }}>
        {this.props.design.designer}
      </p>
    );
    var orderButton = (
      <div className="d-flex justify-content-center float-md-right">
        <a
          className="btn"
          href={this.props.design.orderUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundColor: "#827b5a",
            borderColor: "#827b5a",
            color: "#171717",
            fontSize: "28px"
          }}
        >
          Order Now
        </a>
      </div>
    );
    return (
      <section className="leader-board-item mt-2">
        <div className="row" style={{ backgroundColor: "#fff" }}>
          <div className="col-md-1 mt-auto mb-auto">
            <p className="leaderboard-position-text ml-2 text-md-center">
              {this.props.position}
            </p>
          </div>
          <div className="col col-md-2 mt-auto mb-auto">
            <p
              style={{
                fontSize: "28px"
              }}
            >
              <LargeImageModal
                img={`${srcBase}-1500-1580.jpg`}
                alt={this.props.design.name}
              >
                <img
                  width="150"
                  src={`${srcBase}-600-632.jpg`}
                  alt={this.props.design.name}
                />
              </LargeImageModal>
            </p>
          </div>

          <div className="col col-md-2 mt-auto mb-auto">
            <h2 className="text-capitalize font-weight-bold text-center">
              {this.props.design.name}
            </h2>
            {this.props.design.designer !== null ? designerParagraph : ""}
          </div>

          <div className="col col-md mt-auto mb-auto mr-md-4">
            {this.props.design.orderable ? orderButton : ""}
          </div>
        </div>
        {/*<hr className="mb-0 mt-2" />*/}
      </section>
    );
  }
}

export default LeaderBoardItem;

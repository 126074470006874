const SETTINGS = {
  graphqlEndpoint:
    //"http://localhost:8000/graphql",
    "https://8g2ne39osi.execute-api.eu-west-1.amazonaws.com/api/graphql",
  shopify: {
    domain: "cutso-co.myshopify.com",
    storefrontAccessToken: "6bdfc0557eab4d92197e01726eb680a8"
  }
};

export default SETTINGS;

import React from "react";
import { Button } from "reactstrap";
import Modal from "react-awesome-modal";
import { gql } from "apollo-boost";
import ReactPixel from "react-facebook-pixel";

import client from "../graphql.js";

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        name
        email
      }
    }
  }
`;

export default class JoinExperimentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      modal: false,
      redirect: false,
      showMessage: false,
      name: "",
      email: "",
      error: ""
    };
  }

  checkForm = () => {
    if (!this.state.email) {
      this.setState({ error: "Email is required" });
    }
    if (!this.state.name) {
      this.setState({ error: "Name is required" });
    }

    if (!this.state.name && !this.state.email) {
      this.setState({ error: "Name and Email is required" });
    }

    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!this.state.email.match(pattern)) {
      this.setState({ error: "Invaid Email" });
      return;
    }

    if (this.state.name && this.state.email) {
      this.handleUser();
    }
  };

  handleShowMessage = () => {
    this.setState({
      showMessage: true
    })
}

  handleCloseMessage = () => {
    this.setState({
      showMessage: false
    })
    this.props.handleJoinExperimentModal();
  }

  handleUser = () => {
    
    client
      .mutate({
        mutation: CREATE_USER,
        variables: {
          input: {
            name: this.state.name,
            email: this.state.email
          }
        }
      })
      .then(result => {
        if (!result.error && !result.loading) {
          ReactPixel.track("Lead", {
            content_name: window.location.href
          });
        }
      });
      

    this.handleShowMessage();
  };

  render() {
    return (
      <div>
        <div className="vote-section">
          <Modal
            visible={this.props.best_experience_modal}
            width="700"
            height="300"
            effect="fadeInUp"
            onClickAway={this.props.close}
          >
            <div className="modal-vote-div">
              <h1
                className="modal-title"
                style={{ textAlign: "center", fontSize: "28px" }}
              >
                For The Best Experience{" "}
              </h1>
              <p
                className="modal-text"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  letterSpacing: "0.005em"
                }}
              >
                We pride ourselves in being a mobile-first company. For the best
                possible voting experience, we recommend that you visit our
                voting page on your mobile device.
              </p>

              <Button
                style={{ textAlign: "center" }}
                onClick={() => this.handleBestExperienceModal()}
                className="modal-vote-btn"
                size="md"
                color="dark"
              >
                Okay
              </Button>
            </div>
          </Modal>

          {/**Join the experiment modal and thank you message after signing up for desktop */}
          {
            !this.state.showMessage &&<Modal
            visible={this.props.join_experiment_modal}
            width="700"            
            height="400"
            effect="fadeInUp"
            onClickAway={this.props.close}
          >
           <div className="modal-div">
              <h1 className="modal-title" style={{ textAlign: "center" }}>
                Join The Experiment{" "}
              </h1>
              <p className="modal-text">
                We're building a community of likeminded individuals who are no
                longer willing to turn a blind eye to the negative impact
                fashion brands are having on the world.
              </p>
              <p className="modal-text">
                Join the experiment by entering your info below.
              </p>
              <div className="row">
                <div className="col-md-8">
                  {this.state.error ? (
                    <p style={{ color: "red" }}>{this.state.error}</p>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <div className="row">
                      <label className="col-md-4 control-label">Name</label>
                      <div className="col-md-8">
                        <input
                          onChange={e =>
                            this.setState({ name: e.target.value, error: "" })
                          }
                          type="text"
                          className="form-control"
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-md-4 control-label">Email</label>
                      <div className="col-md-8">
                        <input
                          onChange={e =>
                            this.setState({ email: e.target.value, error: "" })
                          }
                          type="email"
                          className="form-control"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                onClick={this.checkForm}
                className="vote-btn"
                size="md"
                color="dark"
              >
                Join the Experiment
              </Button>
            </div>
            </Modal>
          }
            {this.state.showMessage &&<Modal
            visible={this.props.join_experiment_modal}
            width="500"
            height="300"
            effect="fadeInUp"
            onClickAway={this.props.close}
          > <div className="modal-div">
              <h1 className="modal-title" style={{ textAlign: "center" }}>
              Thank you for signing up! {" "}
              </h1>
              <p className="modal-text" style={{padding: '50px'}}>
              Changing the world isn’t going to be easy, but with more people like you, it’s possible.
              </p>
             
            </div>
            </Modal>
          }
           
        </div>
        
        <div className="vote-section-mobile">
         {/**Join the experiment modal and thank you message after signing up for mobile */}
         {
            !this.state.showMessage &&<Modal
            visible={this.props.join_experiment_modal}
            width="400"
            height="450"
            effect="fadeInUp"
            onClickAway={this.props.close}
          >
            <div
              className="modal-div"
              style={{
                fontSize: "14px",
                color: "#000"
              }}
            >
              <h1 className="modal-title" style={{ textAlign: "center" }}>
                Join The Experiment
              </h1>
              <p className="modal-text">
                We're building a community of likeminded individuals who are no
                longer willing to turn a blind eye to the negative impact
                fashion brands are having on the world.
              </p>
              <p className="modal-text">
                Join the experiment by entering your info below.
              </p>
              <div className="row">
                <div className="col-md-8">
                  {this.state.error ? (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {this.state.error}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <div className="row">
                      <label className="col-md-4 control-label">Name</label>
                      <div className="col-md-8">
                        <input
                          onChange={e =>
                            this.setState({ name: e.target.value })
                          }
                          type="text"
                          className="form-control"
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label className="col-md-4 control-label">Email</label>
                      <div className="col-md-8">
                        <input
                          onChange={e =>
                            this.setState({ email: e.target.value })
                          }
                          type="email"
                          className="form-control"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.error ? (
                <div style={{ marginTop: "-55px", marginLeft: "103px" }}>
                  <Button
                    onClick={this.checkForm}
                    className="vote-btn"
                    size="sm"
                    color="dark"
                  >
                    Join the Experiment
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: "-50px", marginLeft: "103px" }}>
                  <Button
                    onClick={this.checkForm}
                    className="vote-btn"
                    size="sm"
                    color="dark"
                  >
                    Join the Experiment
                  </Button>
                </div>
              )}
            </div>
          </Modal>
          
         }
         {this.state.showMessage &&<Modal
            visible={this.props.join_experiment_modal}
            width="340"
            height="200"
            effect="fadeInUp"
            onClickAway={this.props.close}
          > <div className="modal-div">
              <h1 className="modal-title" style={{ textAlign: "center" }}>
              Thank you for signing up! {" "}
              </h1>
              <p className="modal-text" style={{padding: '30px'}}>
              Changing the world isn’t going to be easy, but with more people like you, it’s possible.
              </p>
             
            </div>
            </Modal>
          }
        </div>
      </div>
    );
  }
}

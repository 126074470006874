import React from "react";
import styled from "styled-components";
import Navigation from "./Navigation/Navigation";
import CarouselSection from "./CarouselSection/CarouselSection";
import VoteSection from "./VoteSection/VoteSection";
import HowItWorks from "./HowItWorks/HowItWorks";
import Footer from "./Footer/Footer";

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
  if (!value) return;

  let flex = (value / 12) * 100;
  return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  // Fix for applying proper box-sizing
  html:not(&) {
    box-sizing: border-box;

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
  }

  body:not(&) {
    font: 16px / 1.618;
  }
`;

// Grid container
const GridContainer = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  // Breakpoint for tablets
  @media (min-width: 576px) {
    max-width: ${remy(540)};
  }

  // Breakpoint for small desktops
  @media (min-width: 768px) {
    max-width: ${remy(720)};
  }

  // Breakpoint for medium desktops
  @media (min-width: 992px) {
    max-width: ${remy(9600)};
  }

  // Breakpoint for large desktops and HD devices
  @media (min-width: 1200px) {
    max-width: ${remy(1140)};
  }
`;

const Circle = styled.div`
  //specific mobile screen Galaxy s5
  @media only screen and (device-width: 360px) and (device-height: 640px) {
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%; /* the magic */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto 40px;
    margin-top: -90px;
  }

  // Columns for mobile
  @media screen and (max-width: 576px) {
    width: 70px;
    height: 70px;
    line-height: 200px;
    border-radius: 50%; /* the magic */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto 40px;
    margin-top: -20px;
    background-color: #fff;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    width: 70px;
    height: 70px;
    line-height: 200px;
    border-radius: 50%; /* the magic */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto 40px;
    margin-top: -20px;
    background-color:#fff;
  }

  /* Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		width: 200px;
		height: 200px;
		line-height: 200px;
		border-radius: 50%; /* the magic *
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		text-align: center;
		color: white;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 700;
		margin: 0 auto 40px;
		margin-top: -90px;
		background-color: #3498db;
	}

	
	*/
`;

// Create layout component -2200-2317.jpg
class HomeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      updateCount: 0
    };
  }

  afterChage = () => {
    this.setState(prevState => ({ updateCount: prevState.updateCount + 1 }));
    //console.log(this.state.updateCount)
  };

  beforeChange = data => {
    this.setState({ slideIndex: data });
    //console.log(this.state.slideIndex)
  };

  render() {
    const mainDivOnestyles = {
      background: `url('Heiki-Background_.jpg')`,
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    };

    const mainDivOnestyles2 = {
      background: `url('Hula-Background_.jpg')`,
      objectFit: "contain",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    };

    const mainDivOnestyles3 = {
      background: `url(${"Suiren-Background_.jpg"})`,
      objectFit: "contain",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat"
    };

    const mainDivOnestyles4 = {
      background: `url(${"Hula-Background_.jpg"})`,
      objectFit: "contain",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    };

    return (
      <div>
        {
          <div
            style={
              this.state.slideIndex == 0
                ? mainDivOnestyles
                : this.state.slideIndex == 1
                ? mainDivOnestyles2
                : this.state.slideIndex == 2
                ? mainDivOnestyles3
                : this.state.slideIndex == 3
                ? mainDivOnestyles4
                : mainDivOnestyles
            }
          >
            <Navigation original={true} />
            <CarouselSection
              afterChage={this.afterChage}
              beforeChange={this.beforeChange}
            />
          </div>
        }
        <Circle>
          <img
            width="25px"
            height="25px"
            style={{ marginTop: "-150px" }}
            src="chevron-down.svg"
            alt="white circle with a down arrow inside"
          />
        </Circle>
        <VoteSection
          afterChage={this.afterChage}
          beforeChange={this.beforeChange}
        />
        <HowItWorks />
        <Footer />
      </div>
    );
  }
}

// Render Layout React element into the DOM
export default HomeCard;

import React, { useState, useRef, useEffect } from "react";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import "./Navigation.css";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { gql } from "apollo-boost";
import client from "../../graphql.js";
const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;


function Navigation(props) {
  const [change, setChange] = useState(false);
  const [navcolor, setNavColor] = useState("#C5C5C4");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(true);
  const [shownav, setShownav] = useState(false);
  const changePosition = 5;

  let position = useWindowScrollPosition();

 
  if (position.y > changePosition && !change) {
    setChange(true);
    setNavColor("#222");
  }

  if (position.y <= changePosition && change) {
    setChange(false);
    setNavColor("#C5C5C4");
  }



  function handleSubmit(event) {
    event.preventDefault();

    // RegEx from: https://stackoverflow.com/a/1373724
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!email.match(pattern)) {
      //this.setState({ valid: false });
      setValid(false);
      return;
    }

    client
      .mutate({
        mutation: CREATE_USER,
        variables: { input: { email: email } }
      })
      .then(result => {
        if (!result.error && !result.loading) {
          setSubmitted(true);
          setEmail("");
          //this.setState({ submitted: true, email: '' });
          ReactPixel.track("Lead", {
            content_name: window.location.href
          });
        }
      });
  }

  function handleChange(event) {
    event.preventDefault();
    setValid(true);
    setEmail(event.target.value);
    //this.setState({ email: event.target.value, valid: true });
  }


  function handleClick(event) {
    const menuBtn = document.querySelector(".menu-btn");
    menuBtn.checked = false;
  }

  const white_logo = "/logo-white.png";

  const dark_logo = "/logo_white.png";

  const white_cart = "/cart-add.svg";

  const dark_cart = "/cart-add-dark.svg";

  let style = {
    backgroundColor: change ? "white" : "transparent",
    transition: "400ms ease",
    height: "80px",
    position: "fixed",
    color: "#0a0a0a",
    right: 0,
    left: 0,
    top: 0
  };

  let textStyle = {
    color:
      !change && props.original && !shownav
        ? "#C5C5C4"
        : change && props.original
        ? "#222222"
        : "#222222",
    display: "block",
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "avenir",
    padding: "20px 20px",
    textDecoration: "none"
  };

  let header = {
    backgroundColor:
      !change && props.original && !shownav
        ? "transparent"
        : change && props.original
        ? "white"
        : "white",
    boxShadow:
      !change && props.original
        ? ""
        : change && props.original
        ? "1px 1px 4px 0 rgba(0, 0, 0, 0.1)"
        : "1px 1px 4px 0 rgba(0, 0, 0, 0.1)",
    position: "fixed",
    width: "100%",
    zIndex: 3
  };

  let showNav = {
    margin: 0,
    padding: 0,
    listStyle: "none",
    overflow: !shownav ? "hidden" : "",
    backgroundColor: shownav ? "#fff" : "",
    color: shownav ? "#222222" : ""
  };

  let emailStyle = {
    height: "20px",
    width: "60%",
    letterSpacing: "2px",
    background: "none",
    border: "0px",
    borderBottom: "2px solid #222222",
    color: change ? "#1e1d23" : "#C5C5C4",
    display: "block",
    margin: "13px auto",
    fontFamily: "avenir",
    fontWeight: "300",
    fontSize: "14px",
    borderBottom: change ? "2px solid #222222" : "2px solid #C5C5C4"
  };

  let buttonStyle = {
    border: "none",
    height: "38px",
    fontSize: "14px",
    padding: "10px",
    backgroundColor: "transparent",
    color:
      !change && props.original
        ? "#C5C5C4"
        : change && props.original
        ? "#222222"
        : "#222222",
    margin: "-1px",
    borderRadius: "0",
    border:
      !change && props.original
        ? "2px solid #C5C5C4"
        : change && props.original
        ? "2px solid #222222"
        : "2px solid #222222",
    width: "135px",
    textTransform: "uppercase",
    position: "relative"
  };

  let logo =
    !change && props.original
      ? white_logo
      : change && props.original
      ? dark_logo
      : dark_logo;

  let cart =
    !change && props.original
      ? white_cart
      : change && props.original
      ? dark_cart
      : dark_cart;

  const Navicon = styled.div`
    & {
      background: ${props => (props.navcolor ? navcolor : navcolor)};
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;
    }

    &:before {
      background: ${props => (props.navcolor ? navcolor : navcolor)};
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
      top: 5px;
    }

    &:after {
      background: ${props => (props.navcolor ? navcolor : navcolor)};
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
      top: -5px;
    }
  `;

  return (
   <header style={header} class="header-home">
        <Link to="/" class="logo">
          <img
            style={{ maxWidth: "100%" }}
            className="logo-light "
            width="80"
            src={!shownav && !change && props.original ? '/logo-white.png': !shownav && !change && !props.original ? '/logo_white.png' : '/logo_white.png' }
            alt="Cutso Logo"
          />
        </Link>
        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn">
          <Navicon
            onClick={() => (!shownav ?  setShownav(true) :  setShownav(false))}
            navcolor
          />
        </label>

        <ul class="menu ul-style2">
        <li class="ul-mobile">
            <Link className="nav-text" style={textStyle} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="nav-text" style={textStyle} to="/leaderboard">
              Leaderboard
            </Link>
          </li>
          <li>
            <Link className="nav-text" style={textStyle} to="/vote">
              Vote
            </Link>
          </li>

          <li>
            <Link className="nav-text" style={textStyle} to="/about">
              About
            </Link>
          </li>
          <li>
            <a
              className="d-md-none nav-text"
              style={textStyle}
              href="#footer"
              onClick={handleClick}
            >
              Join
            </a>
            <div
              className="d-none d-md-block"
              style={{ width: "500px", padding: "10px" }}
            >
              <div className="input-group">
                <input
                  style={emailStyle}
                  id="email"
                  autocomplete="off"
                  type="email"
                  value={email}
                  onChange={event => handleChange(event)}
                  placeholder={
                    submitted ? "Thanks for Joining!" : "Email Address"
                  }
                />
                <span className="input-group-btn">
                  <button
                    onClick={event => handleSubmit(event)}
                    className="btn btn-default "
                    type="button"
                    style={buttonStyle}
                  >
                    JOIN
                  </button>
                </span>
                <small style={{ fontSize: "11px" }}>
                  {valid ? "" : <p className="text-danger">Invalid Email</p>}
                </small>
              </div>
            </div>
          </li>
        </ul>
      </header>
     
  );
}

export default Navigation;

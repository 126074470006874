import Client from "shopify-buy";

import settings from "./settings.js";

const checkoutKey = "shopify/checkout";

export const client = Client.buildClient({
  domain: settings.shopify.domain,
  storefrontAccessToken: settings.shopify.storefrontAccessToken
});

export function getCheckout() {
  return JSON.parse(window.localStorage.getItem(checkoutKey));
}

export function setCheckout(checkout) {
  return window.localStorage.setItem(checkoutKey, JSON.stringify(checkout));
}

export function removeCheckout() {
  return window.localStorage.removeItem(checkoutKey);
}

export function getVariant(name, size) {
  // returns a Promise which returns the variant of the product with name and size specified

  return client.product.fetchAll().then(products => {
    for (const product of products) {
      // TODO: Should I make both lowercase?
      if (product.title.toLowerCase() === name) {
        for (const variant of product.variants) {
          if (variant.title === size) {
            return variant;
          }
        }
      }
    }

    throw new Error(
      `Could not find matching product variant with name: ${name} and size: ${size}`
    );
  });
}

export function getOrCreateCheckout() {
  // Returns a Promise which returns the checkout object

  // Fetch from localStorage
  var checkout = getCheckout();
  if (checkout !== null) {
    console.log(`Checkout Was Stored}`);
    return Promise.resolve().then(() => checkout);
  }

  // Or create a new one
  return client.checkout.create().then(checkout => {
    console.log(`Checkout Was Created}`);
    setCheckout(checkout);
    return checkout;
  });
}

import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
	if (!value) return;

	let width = (value / 12) * 100;
	return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
	if (!value) return;

	let flex = (value / 12) * 100;
	return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;

	// Fix for applying proper box-sizing
	html:not(&) {
		box-sizing: border-box;

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}
	}

	body:not(&) {
		font: 16px / 1.618;
	}
`;

// Grid container
const GridContainer = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	// Breakpoint for tablets
	@media (min-width: 576px) {
		max-width: ${remy(540)};
	}

	// Breakpoint for small desktops
	@media (min-width: 768px) {
		max-width: ${remy(720)};
	}

	// Breakpoint for medium desktops
	@media (min-width: 992px) {
		max-width: ${remy(9600)};
	}

	// Breakpoint for large desktops and HD devices
	@media (min-width: 1200px) {
		max-width: ${remy(1140)};
	}
`;

// Grid row
const GridRow = styled.div`
	margin-right: ${remy(-15)};
	margin-left: ${remy(-15)};
	display: flex;
	flex-wrap: wrap;
`;

const Heading = styled.div`
	//specific mobile screen Galaxy s5
	@media only screen and (device-width: 360px) and (device-height: 640px) {
		letter-spacing: 1px !important;
		font-size: 10px;
		font-family: avenir;
		text-align: center;
	}

	// Columns for mobile
	@media screen and (max-width: 576px) {
		letter-spacing: 3px;
		font-weight: 900;
		font-size: 22px;
		font-family: avenir;
		line-height: 180%;
		text-align: center;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
		letter-spacing: 3px;
		font-weight: 900;
		font-size: 22px;
		font-family: avenir;
		line-height: 180%;
		text-align: center;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px){
		letter-spacing: 3px;
		font-weight: 900;
		font-size: 24px;
		font-family: avenir;
		line-height: 180%;
		text-align: center;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px){
		letter-spacing: 7px;
		font-weight: 900;
		font-size: 30px;
		font-family: avenir;
		line-height: 175%;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		letter-spacing: 7px;
		font-weight: 600;
		font-size: 30px;
		font-family: avenir;
		
	}
`;

const MainDiv = styled.div`
	// Columns for mobile
	@media screen and (max-width: 576px) {
		color: #fff;
		margin: 0px;
		padding: 0px;
		text-align: center;
		width: 100%;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
		color: #fff;
		margin-top: 20px;
		padding: 40px;
		margin-left: 0px;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		color: #fff;
		margin-top: 20px;
		padding: 40px;
		margin-left: 0px;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		color: #fff;
		margin-top: 20px;
		padding: 40px;
		margin-left: 0px;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		color: #fff;
		margin-top: 2vh;
		padding-left: 0px;
		padding-right: 50px;
		padding-bottom: 50px;
		padding-top: 59px;
		margin-left: 90px;
	}
`;

const VisibilityDiv = styled.div`
	// Columns for mobile
	@media (max-width: 576px) {
		display: none;
	}

	// Columns for tablets
	@media (min-width: 576px) {
		display: none;
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		display: initial;
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		display: initial;
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		display: initial;
	}
`;

const VisibilityDiv2 = styled.div`
	// Columns for mobile
	@media (max-width: 576px) {
		display: initial;
	}

	// Columns for tablets
	@media (min-width: 576px) {
		display: initial;
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		display: none;
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		display: none;
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		display: none;
	}
`;

const Text = styled.div`
	// Columns for mobile

	@media screen and (max-width: 576px) {
		margin-top: 10px;
		letter-spacing: 0px;
		font-weight: 400;
		font-size: 11.1px;
		font-family: avenir;
		line-height: 205%;
	}
	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
		margin-top: 10px;
		letter-spacing: 0.5px;
		font-weight: 400;
		font-size: 15px;
		font-family: 'avenir';
		line-height: 205%;
		color: red !important;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		margin-top: 10px;
		letter-spacing: 0.5px;
		font-weight: 400;
		font-size: 15px;
		font-family: 'avenir';
		line-height: 205%;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		margin-top: 10px;
		letter-spacing: 0.5px;
		font-weight: 400;
		font-size: 15px;
		font-family: 'avenir';
		line-height: 205%;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		margin-top: 30px;
		letter-spacing: 0.9px;
		font-weight: 400;
		font-size: 15px;
		font-family: avenir;
		line-height: 205%;
	}
`;

const ImageDiv = styled.div`
	//specific mobile screen Galaxy s5
	@media only screen and (device-width: 360px) and (device-height: 640px) {
		margin-top: -130px;
		
	}

	// Columns for mobile
	@media screen and (max-width: 576px) {
		margin-top: -90px;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
		margin-top: -130px;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		margin-top: -130px;
		margin-left: 60px
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		margin-top: 0px;
		margin-left: 60px
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
		margin-top: 10vh;
		margin-left: -10vh
	}
`;

const Text2 = styled.div`
	//specific mobile screen Galaxy s5
	@media only screen and (device-width: 360px) and (device-height: 640px) {
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
	}

	// Columns for mobile
	@media screen and (max-width: 576px) {
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
		margin-top: -70px;
	}

	// Columns for tablets
	@media screen and (min-width: 576px) and (max-width: 768px) {
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
	}

	// Columns for small desktops
	@media screen and (min-width: 768px) and (max-width: 992px) {
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
	}

	// Columns for medium desktops
	@media screen and (min-width: 992px) and (max-width: 1200px) {
	color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
	}

	// Columns for large desktops and HD devices
	@media screen and (min-width: 1200px) {
	color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 400;
		text-align: center;
		justify-content: center;
	}
`;

// Grid columns
const GridColumn = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};

	// Columns for mobile
	${({ xs }) => (xs ? getFlex(xs) : 'flex: 0 0 100%')};
	${({ xs }) => (xs ? getWidth(xs) : 'width: 100%')};

	// Columns for tablets
	@media (min-width: 576px) {
		${({ sm }) => sm && getFlex(sm)};
		${({ sm }) => sm && getWidth(sm)};
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		${({ md }) => md && getFlex(md)};
		${({ md }) => md && getWidth(md)};
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		${({ lg }) => lg && getFlex(lg)};
		${({ lg }) => lg && getWidth(lg)};
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		${({ xl }) => xl && getFlex(xl)};
		${({ xl }) => xl && getWidth(xl)};
	}
`;

// Create layout component -2200-2317.jpg
class CarouselContent2 extends React.Component {
	flipCard(event) {
		event.currentTarget.classList.toggle('flipped');
	}

	render() {
		return (
			<GridContainer style={{ marginTop: '30px' }}>
				<GridRow>
					<GridColumn xs="12" sm="12" md="6" lg="6" xl="6">
						<MainDiv>
						<VisibilityDiv><Heading>ELIMINATING <br />THE WASTE <br /> CREATED BY <br /> UNSOLD<br /> STOCK </Heading></VisibilityDiv>
						<VisibilityDiv2><Heading>ELIMINATING THE WASTE CREATED BY UNSOLD STOCK </Heading> </VisibilityDiv2>
							
						<VisibilityDiv><Text>CROWD-DESIGNED SUSTAINABLE <br /> SHIRTS, HANDMADE IN ITALY, BY <br /> ADULTS EARNING A LIVING <br /> WAGE</Text></VisibilityDiv>
						<VisibilityDiv2><Text>CROWD-DESIGNED SUSTAINABLE SHIRTS, HANDMADE IN ITALY, BY ADULTS EARNING A LIVING WAGE</Text></VisibilityDiv2>
							

							<GridRow style={{ marginTop: '10px' }}>
								<GridColumn
									style={{
										textAlign: 'start',
										justifyContent: 'start',
									}}
									xs="12"
									sm="12"
									md="6"
									lg="6"
									xl="6"
								>
									<VisibilityDiv>
										<Button
											style={{
												width: '250px',
												backgroundColor: '#fff',
												fontWeight: '900',
												color: '#222222',
												fontSize: '16px',
												height: '45px',

												borderRadius: '0px',
											}}
											size="lg"
											color="dark"
										>
											<Link
												style={{
													color: '#222222',
												}}
												to="/vote"
											>
												BACK YOUR STYLE
											</Link>
										</Button>
									</VisibilityDiv>
								</GridColumn>
								<GridColumn
									style={{
										textAlign: 'center',
										justifyContent: 'center',
										padding: '10px',
									}}
									xs="12"
									sm="12"
									md="6"
									lg="6"
									xl="6"
								></GridColumn>
							</GridRow>
						</MainDiv>
					</GridColumn>
					<GridColumn
						style={{ textAlign: 'center', justifyContent: 'center' }}
						xs="12"
						sm="12"
						md="6"
						lg="6"
						xl="6"
					>
						<VisibilityDiv>
						{/* "/Suiren-Shirt.png"}
							<h3 style={{ color: '#fff' }}>Beat</h3>
							<p
								style={{
									color: '#fff',
									letterSpacing: '0.5px',
									fontWeight: '400',
									fontSize: '13px',
									fontFamily: 'avenir',
								}}
							>
								DESIGNED BY T.C.S
							</p>
							*/}
							
						</VisibilityDiv>

						
						<ImageDiv>
							<img style={{ objectFit: 'contain', maxWidth: '100%' }} witdh="450px" height="450px" src="/Hula-shirt_.png" />
						</ImageDiv>
						{/*<Text2> 5% FUNDED (4 DAYS LEFT)</Text2>*/}
                        <VisibilityDiv2>
						
						
										<Button
											style={{
												width: '250px',
												backgroundColor: '#fff',
												fontWeight: '900',
												color: '#222222',
												fontSize: '16px',
												height: '45px',

												borderRadius: '0px',
											}}
											size="lg"
											color="dark"
										>
											<Link
												style={{
													color: '#222222',
												}}
												to="/vote"
											>
												BACK YOUR STYLE
											</Link>
										</Button>
									</VisibilityDiv2>
									
					</GridColumn>
				</GridRow>
			</GridContainer>
		);
	}
}

// Render Layout React element into the DOM
export default CarouselContent2;

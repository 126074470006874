import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import About from "./pages/About";
import LeaderBoard from "./pages/LeaderBoard";
import Vote from "./pages/Vote";
import "./App.css";

import ReactPixel from "react-facebook-pixel";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Home2 from "./Home/Home2";
import ProductPage from "./pages/Product";

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" name="Home2" component={Home2} />
          <Route exact path="/about" name="About" component={About} />
          <Route
            exact
            path="/leaderboard"
            name="LeaderBoard"
            component={LeaderBoard}
          />
          <Route exact path="/vote" name="Vote" component={Vote} />
          <Route exact path="/careers" name="Careers" component={Careers} />
          <Route exact path="/contact" name="Contact" component={Contact} />
          <Route exact path="/product" name="Product" component={ProductPage} />
          <Route
            path="/product/:id"
            component={match => new ProductPage(match)}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  componentDidMount() {
    ReactPixel.init(413881129318661);
    ReactPixel.pageView();
  }
}

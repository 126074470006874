import React from "react";
import { gql } from "apollo-boost";

import DropTimer from "../components/DropTimer.js";
//import Navigation from "../components/Navigation.js";
import Footer from "../Home/Footer/Footer";
import LeaderBoardItem from "../components/leaderboard/LeaderBoardItem.js";
import JoinExperimentModal from "../components/JoinExperimentModal.js";
import client from "../graphql.js";
import Navigation from '../Home/Navigation/Navigation';

export default class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      input: false,
      visible: false,
      designs: {}
    };

    fetchDesigns().then(result => {
      if (result.loading) {
      }
      this.setState({ designs: result.data.designs });
    });

    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.modal = this.modal.bind(this);
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  modal = () => {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  };

  onClick(event) {
    event.preventDefault();
    fetchDesigns(this.state.designs.pageInfo.endCursor).then(result => {
      if (result.loading) {
      }
      this.setState(state => {
        return {
          designs: {
            pageInfo: {
              endCursor: result.data.designs.pageInfo.endCursor,
              hasNextPage: result.data.designs.pageInfo.hasNextPage
            },
            edges: state.designs.edges.concat(result.data.designs.edges)
          }
        };
      });
    });
  }

  componentDidMount() {
    fetchDesigns();
  }

  render() {
    var nextPage = (
      <div className="row mt-2 mb-2">
        <div className="col d-flex justify-content-center">
          <button
            className="btn"
            onClick={this.onClick}
            style={{
              backgroundColor: "#827b5a",
              borderColor: "#827b5a",
              color: "#171717",
              fontSize: "28px"
            }}
          >
            More Designs
          </button>
        </div>
      </div>
    );
    return (
      <div class="app">
      {/*
        <Navigation
          modal={this.modal}
          state="home"
          toggle={this.toggle}
          color="#171717"
        />
        */}
        <Navigation original={false} />

        <section class="pt-5" id="leaderboard-title-container">
          <div className="row" style={{ backgroundColor: "#827b5a" }}>
            <div className="col-12 col-md-6 mt-auto mb-auto">
              <div className="d-flex flex-column">
                <div className="align-self-center align-self-md-start">
                  <i
                    style={{ fontSize: "50px" }}
                    class="fa fa-trophy pl-0 pt-5 pr-5 pb-5 p-md-5"
                    aria-hidden="true"
                  />
                  <h1 className="d-inline font-weight-bold">Top Designs</h1>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-auto mb-auto">
              <div className="float-md-right mr-md-3">
                <DropTimer className="align-self-center" />
              </div>
            </div>
          </div>
        </section>

        {!objectIsEmpty(this.state.designs) ? (
          this.state.designs.edges.map((edge, index) => (
            <LeaderBoardItem
              key={edge.node.id}
              design={edge.node}
              position={index + 1}
            />
          ))
        ) : (
          <div style={{ height: "100vh" }} />
        )}

        {!objectIsEmpty(this.state.designs) &&
        this.state.designs.pageInfo.hasNextPage
          ? ""//nextPage
          : ""}

        <JoinExperimentModal
          handleJoinExperimentModal={this.modal}
          close={this.modal}
          join_experiment_modal={this.state.visible}
        />
<hr />
        <Footer />
      </div>
    );
  }
}

function fetchDesigns(after, first = 10) {
  var query = gql`
    query fetchDesigns($after: String, $first: Int) {
      designs(
        after: $after
        first: $first
        sort: [ORDERABLE_DESC, UPVOTES_DESC, ID_ASC]
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            name
            designer {
              name
            }
            image
            orderable
            orderUrl
            upvotes
          }
        }
      }
    }
  `;

  return client.query({
    query: query,
    variables: { first: first, after: after }
  });
}

function objectIsEmpty(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

import ApolloClient, { gql } from "apollo-boost";
import SETTINGS from "./settings.js";

const client = new ApolloClient({
  uri: SETTINGS.graphqlEndpoint
});

const upVote = gql`
  mutation Upvote($input: UpvoteInput!) {
    upvote(input: $input) {
      design {
        id
      }
    }
  }
`;

const downVote = gql`
  mutation Downvote($input: DownvoteInput!) {
    downvote(input: $input) {
      design {
        id
      }
    }
  }
`;

export { client as default, upVote, downVote };

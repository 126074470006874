import React from "react";

const buttonStyles = {
  padding: "30px 30px",
  //background: "whitesmoke",
  cursor: "pointer",
  border: "none",
  borderRadius: 3,
  marginTop: "20px"
};

const IconButton = ({ children, onClick }) => (
  <div onClick={onClick} style={{ ...buttonStyles }}>
    {children}
  </div>
);

export default IconButton;

import React from "react";

import facebook from "../images/facebook.svg";
import twitter from "../images/twitter.svg";

class SocialShareLink extends React.Component {
  constructor(props) {
    super(props);

    this.imageSrc = undefined;
    this.imageAlt = undefined;
    this.anchorHrefTemplate = undefined;
    this.anchorText = undefined;
    this.windowTitle = undefined;

    this.onClick = this.onClick.bind(this);
  }

  render() {
    return (
      <div className="d-inline">
        <img src={this.imageSrc} alt={this.imageAlt} height="20" />{" "}
        <a
          href={this.anchorHrefTemplate(encodeURI(this.props.textOrUrl))}
          onClick={this.onClick}
        >
          {this.anchorText}
        </a>
      </div>
    );
  }

  onClick(event) {
    event.preventDefault();
    window.open(
      event.target.href,
      this.windowTitle,
      // TODO: https://developer.mozilla.org/en-US/docs/Web/API/Window/open#Window_features
      "left=20,top=20,width=500,height=500,toolbar=1,resizable=0"
    );
    // TODO: Why return false here?
    return false;
  }
}

class FacebookShare extends SocialShareLink {
  constructor(props) {
    super(props);

    this.imageSrc = facebook;
    this.imageAlt = "Facebook Icon";
    this.anchorHrefTemplate = url =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    this.anchorText = "Share";
    this.windowTitle = "Share on Facebook";
  }
}

class TwitterShare extends SocialShareLink {
  constructor(props) {
    super(props);

    this.imageSrc = twitter;
    this.imageAlt = "Twitter Icon";
    this.anchorHrefTemplate = text =>
      `https://twitter.com/intent/tweet?text=${text}`;
    this.anchorText = "Tweet";
    this.windowTitle = "Share to Twitter";
  }
}

export { FacebookShare, TwitterShare };

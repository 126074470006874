import React from 'react';
import styled, { css } from 'styled-components';

// Cache container
const container = document.querySelector('.container');

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
	if (!value) return;

	let width = (value / 12) * 100;
	return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
	if (!value) return;

	let flex = (value / 12) * 100;
	return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;

	// Fix for applying proper box-sizing
	html:not(&) {
		box-sizing: border-box;

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}
	}

	body:not(&) {
		font: 16px / 1.618;
	}
`;

// Grid container
const GridContainer = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	// Breakpoint for tablets
	@media (min-width: 576px) {
		max-width: ${remy(540)};
	}

	// Breakpoint for small desktops
	@media (min-width: 768px) {
		max-width: ${remy(720)};
	}

	// Breakpoint for medium desktops
	@media (min-width: 992px) {
		max-width: ${remy(9600)};
	}

	// Breakpoint for large desktops and HD devices
	@media (min-width: 1200px) {
		max-width: ${remy(1140)};
	}
`;

// Grid row
const GridRow = styled.div`
	margin-right: ${remy(-15)};
	margin-left: ${remy(-15)};
	display: flex;
	flex-wrap: wrap;
`;

// Create layout component
class AboutCardTwo extends React.Component {
	flipCard(event) {
		event.currentTarget.classList.toggle('flipped');
	}

	render() {
		return (
			<LayoutWrapper style={{backgroundColor: '#171717', paddingTop: '120px'}}>
				<GridContainer>
					<GridRow style={{textAlign: 'center', justifyContent: 'center'}}>
						<h4 className="cutso-mission-header">The Cutso Experiments</h4>
						<p className="text-center" style={{ color: 'white', fontSize: '18px', padding: '30px', textAlign: 'center' }}>
							By 2050, 3 planets worth of resources will be required to maintain current fashion industry trends.
							<br />
							<br />
							That is the definition of unsustainable.
							<br />
							<br />
							For the sake of our planet (this one, not the other two), we need to change the way we produce and consume our clothes.
							<br />
							<br />
							Whilst “big fashion” claiming they’ll use 100% sustainable materials by 2025 is a step in the right direction, it’s a “band-aid” fix that ignores a much bigger problem.
							<br />
							<br />
							The 45 billion garments that go unsold every year.
                            <br />
                            <br />
                            Garments that get warehoused, put in the rubbish or worse, burned.
                            <br />
                            <br />
                            Cutso is a startup that is solving the $500bn problem of over production by developing a model to eliminate the waste from unsold stock.
                            <br />
                            <br />
                            Like any good startup, we’re leveraging technology to experiment to a new solution.
                            <br />
                            <br />
                            Our vision is to solve the over production problem with digitally printed shirts, then make our technology available to other brands & designers, so that they can provide their customers with sustainably produced clothes, and help eliminate the waste from unsold stock.
						</p>
					</GridRow>
				</GridContainer>
			</LayoutWrapper>
		);
	}
}

// Render Layout React element into the DOM
export default AboutCardTwo;

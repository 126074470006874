import React from "react";
import { Modal } from "reactstrap";

class LargeImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    event.preventDefault();
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <a href="#none" onClick={this.toggle}>
          {this.props.children}
        </a>
        <Modal size="lg" isOpen={this.state.isOpen} toggle={this.toggle}>
          <img alt={this.props.alt} src={this.props.img} className="mw-100" />
        </Modal>
      </div>
    );
  }
}

export default LargeImageModal;

import React, { Component } from 'react';
import Slider from 'react-slick';
import { baseUrl } from './config';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled, { css } from 'styled-components';
import CarouselContent from '../CarouselContent/CarouselContent';
import CarouselContent2 from '../CarouselContent/CarouselContent2';
import CarouselContent3 from '../CarouselContent/CarouselContent3';
import './CarouselSection.css';
function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<img
			className={className}
			style={{ ...style, width: '100px', height: '100px' }}
			onClick={onClick}
			src="/chevron-right-big.svg"
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<img
			className={className}
			style={{ ...style, width: '100px', height: '100px' }}
			onClick={onClick}
			src="/chevron-left-big.svg"
		/>
	);
}

const VisibilityDiv = styled.div`
	// Columns for mobile
	@media (max-width: 576px) {
		display: none;
	}

	// Columns for tablets
	@media (min-width: 576px) {
		display: none;
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		display: initial;
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		display: initial;
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		display: initial;
	}
`;


const VisibilityMobile = styled.div`
	// Columns for mobile
	@media (max-width: 576px) {
		display: initial;
	}

	// Columns for tablets
	@media (min-width: 576px) {
		display: none;
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		display: none;
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		display: none;
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		display: none;
	}
`;

const VisibilityTablet = styled.div`
	// Columns for mobile
	@media (max-width: 576px) {
		display: none;
	}

	// Columns for tablets
	@media (min-width: 576px) {
		display: initial;
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		display: none;
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		display: none;
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		display: none;
	}
`;

export default class CarouselSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const settings = {
			autoplay: false,
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow className="arrow" />,
			prevArrow: <SamplePrevArrow style={{ width: '100px', height: '100px' }} />,
			afterChange: () => this.props.afterChange,
			beforeChange: (current, next) => this.props.beforeChange(next),
		};

		return (
			<div>
				<VisibilityDiv>
					<div style={{ padding: '50px' }}>
						<Slider ref={slider => (this.slider = slider)} {...settings}>
							<div>
								<CarouselContent />
							</div>
							<div>
								<CarouselContent2 />
							</div>
							<div>
								<CarouselContent3 />
							</div>
						</Slider>
					</div>
				</VisibilityDiv>
				<VisibilityMobile>
					<div style={{ padding: '20px' }}>
						<Slider ref={slider => (this.slider = slider)} {...settings}>
							<div>
								<CarouselContent />
							</div>
							<div>
								<CarouselContent2 />
							</div>
							<div>
								<CarouselContent3 />
							</div>
						</Slider>
					</div>
				</VisibilityMobile>
				<VisibilityTablet>
					<div style={{ padding: '80px' }}>
						<Slider ref={slider => (this.slider = slider)} {...settings}>
							<div>
								<CarouselContent />
							</div>
							<div>
								<CarouselContent2 />
							</div>
							<div>
								<CarouselContent3 />
							</div>
						</Slider>
					</div>
				</VisibilityTablet>
			</div>
		);
	}
}

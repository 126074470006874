import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import "./Footer.css";
import { gql } from "apollo-boost";
import ReactPixel from "react-facebook-pixel";
import client from "../../graphql.js";

let buttonStyle = {
  border: "none",
  height: "38px",
  fontSize: "14px",
  padding: "10px",
  backgroundColor: "transparent",
  color: "#222222",
  margin: "-1px",
  borderRadius: "0",
  border: "2px solid #222222",
  width: "135px",
  textTransform: "uppercase",
  position: "relative"
};

let emailStyle = {
  height: "20px",
  width: "60%",
  letterSpacing: "2px",
  background: "none",
  border: "0px",
  borderBottom: "2px solid #222222",
  color: "#949494",
  display: "block",
  margin: "13px auto",
  marginLeft: "-2px",
  fontFamily: "avenir",
  fontWeight: "300",
  fontSize: "14px",
  borderBottom: "2px solid #222222"
};

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;
// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
  if (!value) return;

  let flex = (value / 12) * 100;
  return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;

  // Fix for applying proper box-sizing
  html:not(&) {
    box-sizing: border-box;

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
  }

  body:not(&) {
    font: 16px / 1.618;
  }
`;

// Grid container
const GridContainer = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  // Breakpoint for tablets
  @media (min-width: 576px) {
    max-width: ${remy(540)};
  }

  // Breakpoint for small desktops
  @media (min-width: 768px) {
    max-width: ${remy(720)};
  }

  // Breakpoint for medium desktops
  @media (min-width: 992px) {
    max-width: ${remy(9600)};
  }

  // Breakpoint for large desktops and HD devices
  @media (min-width: 1200px) {
    max-width: ${remy(1140)};
  }
`;

// Grid row
const GridRow = styled.div`
  margin-right: ${remy(-15)};
  margin-left: ${remy(-15)};
  display: flex;
  flex-wrap: wrap;
`;

const Heading = styled.div`
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 18px;
  line-height: 150%;
`;

const Text = styled.div`
  font-family: avenir;
`;

// Grid columns
const GridColumn = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};

  // Columns for mobile
  ${({ xs }) => (xs ? getFlex(xs) : "flex: 0 0 100%")};
  ${({ xs }) => (xs ? getWidth(xs) : "width: 100%")};

  // Columns for tablets
  @media (min-width: 576px) {
    ${({ sm }) => sm && getFlex(sm)};
    ${({ sm }) => sm && getWidth(sm)};
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    ${({ md }) => md && getFlex(md)};
    ${({ md }) => md && getWidth(md)};
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    ${({ lg }) => lg && getFlex(lg)};
    ${({ lg }) => lg && getWidth(lg)};
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    ${({ xl }) => xl && getFlex(xl)};
    ${({ xl }) => xl && getWidth(xl)};
  }
`;
/*
const Navicon = styled.div`
& {
	background: ${props => (props.navcolor ? navcolor : navcolor)};
	display: block;
	height: 2px;
	position: relative;
	transition: background 0.2s ease-out;
	width: 18px;
}

&:before {
	background: ${props => (props.navcolor ? navcolor : navcolor)};
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all 0.2s ease-out;
	width: 100%;
	top: 5px;
}

&:after {
	background: ${props => (props.navcolor ? navcolor : navcolor)};
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all 0.2s ease-out;
	width: 100%;
	top: -5px;
}
`;
*/

const Email = styled.div`
  height: 20px;
  width: 60%;
  letter-spacing: 2px;
  background: none;
  border: 0px;
  border-bottom: 2px solid #222222;
  color: #222222;
  display: block;
  margin: 13px auto;
  font-family: avenir;
  font-weight: 300;
  font-size: 14px;
`;

const SubscribeForm = styled.div`
  & {
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
  }

  &:focus {
    outline: none;
  }
`;

const FormInput = styled.div`
  & {
    height: 44px;
  }
`;

const FormButton = styled.div`
  & {
    border: none;
    height: 38px;
    font-size: 14px;
    padding: 10px;
    background-color: transparent;
    color: #222222;
    margin: -1px;
    border-radius: 0;
    border: 2px solid #222222;
    width: 135px;
    text-transform: uppercase;
    position: relative;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }

  &:focus,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
    background-color: #827b5a;
    outline: none;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }

  &:focus,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
    background-color: #827b5a;
    outline: none;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
  }
`;

// Create layout component -2200-2317.jpg
class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      valid: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  static defaultProps = {
    color: "#fff",
    backgroundColor: "#827b5a",
    size: ""
  };

  handleSubmit(event) {
    event.preventDefault();

    // RegEx from: https://stackoverflow.com/a/1373724
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!this.state.email.match(pattern)) {
      this.setState({ valid: false });
      return;
    }

    client
      .mutate({
        mutation: CREATE_USER,
        variables: { input: { email: this.state.email } }
      })
      .then(result => {
        if (!result.error && !result.loading) {
          this.setState({ submitted: true, email: "" });
          ReactPixel.track("Lead", {
            content_name: window.location.href
          });
        }
      });
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ email: event.target.value, valid: true });
  }
  render() {
    return (
      <footer id="footer">
        <div className="footer-big">
          {/* start .container */}
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <p className="footer-widget-title-main">
                      SIGNUP FOR CUTSO UPDATES
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <ul className="ul-parent">
                      <li className="li-style ">
                        <SubscribeForm>
                          <div className="input-group">
                            <input
                              style={emailStyle}
                              autocomplete="off"
                              type="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              placeholder={
                                this.state.submitted
                                  ? "Thanks for Joining!"
                                  : "Email Address"
                              }
                            />
                            <span className="input-group-btn">
                              <button
                                onClick={this.handleSubmit}
                                className="btn btn-default "
                                type="button"
                                style={buttonStyle}
                              >
                                JOIN
                              </button>
                            </span>
                            <small style={{ fontSize: "11px" }}>
                              {this.state.valid ? (
                                ""
                              ) : (
                                <p className="text-danger">Invalid Email</p>
                              )}
                              By entering your email address you agree to
                              receive email updates and newsletter. You can
                              unsubscribe at any time
                            </small>
                          </div>
                        </SubscribeForm>
                      </li>
                      <li className="li-style " />
                      <li className="li-style" style={{ marginTop: "20px" }}>
                        {" "}
                        <Text>
                          CONNECT WITH &nbsp;{" "}
                          <img width="25" height="25" src="facebook.svg" />
                          &nbsp;&nbsp;
                          <a
                            className="a-style"
                            href="https://instagram.com/cutso.co/"
                            target="_blank"
                          >
                            <img width="25" height="25" src="/instagram.svg" />
                          </a>
                        </Text>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
              </div>
              {/*
								<div className="footer-widget">
									<div className="widget-about">
										<div id="subscription_area">
											<div className="container">
												<div className="row">
													<div className="col-md-12 col-sm-12">
														<div className="subscribe_now">
                                                        <p className="footer-widget-title">USEFUL LINKS</p>
															<form className="subscribe_form">
																<div className="input-group">
																	<input
																		type="text"
																		className="form-control"
																		name="email"
																		placeholder="Enter your email"
																	/>
																	<span className="input-group-btn">
																		<button
																			className="btn btn-default"
																			type="button"
																		>
																			subscribe
																		</button>
																	</span>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>

										<p>
											Что если тут написать другой текст на русском языке, для проверки шрифта
											Rubik )
										</p>
										<ul className="contact-details">
											<li className="li-style ">
												<span className="icon-earphones" /> Call Us:
												<a href="tel:344-755-111">344-755-111</Text></Link>
											</li>
											<li className="li-style ">
												<span className="icon-envelope-open" />
												<a href="mailto:support@aazztech.com">support@aazztech.com</Text></Link>
											</li>
										</ul>
									</div>
								</div>
								{/* Ends: .footer-widget *
							</div>
                             */}
              {/* end /.col-md-4 */}
              <div className="col-md-3 col-sm-4 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <p className="footer-widget-title">GET TO KNOW US</p>
                    <ul className="ul-parent">
                      <li className="li-style ">
                        <Link to="/about">
                          {" "}
                          <Text> Our Team </Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/about">
                          {" "}
                          <Text>Our Manufacturers</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/">
                          {" "}
                          <Text>How It Works</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/contact">
                          {" "}
                          <Text>Contact Us</Text>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* end /.col-md-3 */}
              <div className="col-md-3 col-sm-4 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <p className="footer-widget-title">DESIGNS</p>
                    <ul className="ul-parent">
                      <li className="li-style ">
                        <Link to="/submit_design">
                          {" "}
                          <Text>Submit Your Designs</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/leaderboard">
                          {" "}
                          <Text>Leaderboard</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/vote">
                          {" "}
                          <Text>Vote</Text>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* end /.col-lg-3 */}
              <div className="col-md-2 col-sm-4 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <p className="footer-widget-title ">USEFUL LINKS</p>
                    <ul className="ul-parent">
                      <li className="li-style ">
                        <Link to="/">
                          {" "}
                          <Text>Size Guide</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/">
                          {" "}
                          <Text>Delivery &amp; Return</Text>
                        </Link>
                      </li>
                      <li className="li-style ">
                        <Link to="/careers">
                          {" "}
                          <Text>Careers </Text>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* Ends: .col-lg-3 */}
            </div>
            {/* end /.row */}

            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6">
                <div className="footer-widget-two">
                  <div className="footer-menu no-padding">
                    <p className="footer-widget-title-right ">© 2020 Cutso</p>
                  </div>
                  {/* end /.footer-menu */}
                </div>
              </div>
              {/*
								<div className="footer-widget">
									<div className="widget-about">
										<div id="subscription_area">
											<div className="container">
												<div className="row">
													<div className="col-md-12 col-sm-12">
														<div className="subscribe_now">
                                                        <p className="footer-widget-title">USEFUL LINKS</p>
															<form className="subscribe_form">
																<div className="input-group">
																	<input
																		type="text"
																		className="form-control"
																		name="email"
																		placeholder="Enter your email"
																	/>
																	<span className="input-group-btn">
																		<button
																			className="btn btn-default"
																			type="button"
																		>
																			subscribe
																		</button>
																	</span>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>

										<p>
											Что если тут написать другой текст на русском языке, для проверки шрифта
											Rubik )
										</p>
										<ul className="contact-details">
											<li className="li-style ">
												<span className="icon-earphones" /> Call Us:
												<a href="tel:344-755-111">344-755-111</Text></Link>
											</li>
											<li className="li-style ">
												<span className="icon-envelope-open" />
												<a href="mailto:support@aazztech.com">support@aazztech.com</Text></Link>
											</li>
										</ul>
									</div>
								</div>
								{/* Ends: .footer-widget *
							</div>
                             */}

              <div className="col-md-6 col-sm-6 col-xs-6">
                <div className="footer-widget-two">
                  <div className="footer-menu no-padding">
                    <p className="footer-widget-title-left ">
                      Privacy &amp; Cookies | Terms &amp; Conditions{" "}
                    </p>
                  </div>
                  {/* end /.footer-menu */}
                </div>
                {/* Ends: .footer-widget */}
              </div>
              {/* Ends: .col-lg-3 */}
            </div>
            {/* end /.row */}
          </div>
          {/* end /.container */}
        </div>
        {/* end /.footer-big 
				<div className="mini-footer">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="copyright-text">
									<p>
										© 2018
										<Link to="/"> <Text>DigiPro</Text></Link>. All rights reserved. Created by
										<Link to="/"> <Text>AazzTech</Text></Link>
									</p>
								</div>
								<div className="go_top">
									<span className="icon-arrow-up" />
								</div>
							</div>
						</div>
					</div>
				</div>
                */}
      </footer>
    );
  }
}

// Render Layout React element into the DOM
export default Footer;

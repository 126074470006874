import React from 'react';
import styled, { css } from 'styled-components';

// Cache container
const container = document.querySelector('.container');

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
	if (!value) return;

	let width = (value / 12) * 100;
	return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
	if (!value) return;

	let flex = (value / 12) * 100;
	return `flex: 0 0 ${flex}%;`;
};

const LayoutWrapper = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;

	// Fix for applying proper box-sizing
	html:not(&) {
		box-sizing: border-box;

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}
	}

	body:not(&) {
		font: 16px / 1.618;
	}
`;

// Grid container
const GridContainer = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	// Breakpoint for tablets
	@media (min-width: 576px) {
		max-width: ${remy(540)};
	}

	// Breakpoint for small desktops
	@media (min-width: 768px) {
		max-width: ${remy(720)};
	}

	// Breakpoint for medium desktops
	@media (min-width: 992px) {
		max-width: ${remy(9600)};
	}

	// Breakpoint for large desktops and HD devices
	@media (min-width: 1200px) {
		max-width: ${remy(1140)};
	}
`;

// Grid row
const GridRow = styled.div`
	margin-right: ${remy(-15)};
	margin-left: ${remy(-15)};
	display: flex;
	flex-wrap: wrap;
`;

// Grid columns
const GridColumn = styled.div`
	padding-right: ${remy(15)};
	padding-left: ${remy(15)};

	// Columns for mobile
	${({ xs }) => (xs ? getFlex(xs) : 'flex: 0 0 100%')};
	${({ xs }) => (xs ? getWidth(xs) : 'width: 100%')};

	// Columns for tablets
	@media (min-width: 576px) {
		${({ sm }) => sm && getFlex(sm)};
		${({ sm }) => sm && getWidth(sm)};
	}

	// Columns for small desktops
	@media (min-width: 768px) {
		${({ md }) => md && getFlex(md)};
		${({ md }) => md && getWidth(md)};
	}

	// Columns for medium desktops
	@media (min-width: 992px) {
		${({ lg }) => lg && getFlex(lg)};
		${({ lg }) => lg && getWidth(lg)};
	}

	// Columns for large desktops and HD devices
	@media (min-width: 1200px) {
		${({ xl }) => xl && getFlex(xl)};
		${({ xl }) => xl && getWidth(xl)};
	}
`;

// Flipping card
const Card = styled.article`
	position: relative;
	width: 100%;
	min-height: ${remy(380)};
	cursor: pointer;
	perspective: 1000px;
	margin-top: 10px;
	transition: all 0.25s ease-in-out;

	&:focus,
	&:hover {
		box-shadow: 0 0 ${remy(40)} rgba(0, 0, 0, 0.15);
	}

	&.flipped {
		& > div:first-of-type {
			// Front side of the card
			transform: perspective(1000px) rotateY(-180deg);
		}

		& > div:last-of-type {
			// Back side of the card
			transform: perspective(1000px) rotateY(0deg);
		}
	}
`;

// Card sides
const CardSide = css`
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	padding: ${remy(24)};
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transition: all 0.25s ease-in-out;
`;

// Card side - front
const CardFrontOne = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Tyler_Couto.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - front
const CardFrontTwo = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Michele_Pontillo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - front
const CardFrontThree = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Andrew.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - front
const CardFrontFour = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Chris_Susskind.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - front
const CardFrontFive = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Rebecca.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - front
const CardFrontSix = styled.div`
	${CardSide};

	font-weight: bold;
	text-align: center;
	background-image: url('/team/Dave_F.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

// Card side - back
const CardBack = styled.div`
	${CardSide};

	transform: rotateY(-180deg);
`;

// Card content
const CardNumber = styled.span`
	display: block;
	font-size: ${remy(24)};
`;

const CardTitle = styled.h2`
	margin-top: 150px;
	font-size: ${remy(21)};
`;

const CardButton = styled.button`
	transition: all 0.5s ease;
	color: #000;
	border: 3px solid white;
	/*font-family:'Montserrat', sans-serif;*/
	text-transform: uppercase;
	text-align: center;
	line-height: 1;
	font-size: 17px;
	background-color: #fff;
	padding: 10px;
	outline: none;
	border-radius: 4px;
	margin-top: 300px;
`;

const CardBackTitle = styled.h2`
	text-align: center;
	font-size: ${remy(21)};
`;

const CardBackLocation = styled.h5`
	text-align: center;
	font-size: ${remy(18)};
`;

const CardDestyledription = styled.h5`
	text-align: center;
	font-size: ${remy(16)};
`;

// Create layout component
class AboutCard extends React.Component {
	flipCard(event) {
		event.currentTarget.classList.toggle('flipped');
	}

	render() {
		return (
			<LayoutWrapper>
				<GridContainer>
					<GridRow>
						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontOne>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontOne>

								<CardBack>
									<CardBackTitle>Tyler Couto-(Full-stack engineer focused on backend)</CardBackTitle>
									<CardBackLocation> SF Bay Area, CA, USA</CardBackLocation>
									<CardDestyledription>
										{' '}
										I joined Cutso, because I want to contribute to a sustainable future. Cutso is
										working toward that future by aiming to produce only what can be sold.
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>

						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontTwo>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontTwo>

								<CardBack>
									<CardBackTitle>Michele Pontillo-(Head of Physical Product)</CardBackTitle>
									<CardBackLocation>Bergamo, Italy</CardBackLocation>
									<CardDestyledription>
										{' '}
										I met Dave through his previous business, and loved what he did with Maple.
										Having spent a few decades in the fashion world, I wanted to use my expertise to
										make beautiful garments, the right way.
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>

						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontThree>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontThree>

								<CardBack>
									<CardBackTitle>Andrew Mole-(Co-Founder, COO)</CardBackTitle>
									<CardBackLocation>London, UK</CardBackLocation>
									<CardDestyledription>
										{' '}
										Having spent a decade building companies in the advertising and technology
										industries I sought to work on something ‘good’. In both environmental and
										social impact Cutso fit the bill. Beyond this, I was intrigued by founder,
										Dave’s passion.
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>

						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontFour>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontFour>

								<CardBack>
									<CardBackTitle> Chris Susskind-(Head of Product)</CardBackTitle>
									<CardBackLocation>Nashville, TN, USA</CardBackLocation>
									<CardDestyledription>
										{' '}
										I joined Cutso because I believe clothes are an extension of ourselves. Today,
										environmental problems can no longer be ignored. Cutso gives people the tools to
										keep conservation and sustainability at the forefront of global attention -
										while looking great!
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>

						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontFive>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontFive>

								<CardBack>
									<CardBackTitle>Rebecca Oshiobughie-(Fullstack and DevOps Engineer)</CardBackTitle>
									<CardBackLocation>Lagos, Nigeria</CardBackLocation>
									<CardDestyledription>
										{' '}
										I joined Cutso because I want to be part of the team solving a crucial problem
										in the fashion industry.
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>

						<GridColumn sm="6" lg="4">
							<Card onClick={this.flipCard.bind(this)}>
								<CardFrontSix>
									<CardNumber></CardNumber>

									<CardButton>View Bio</CardButton>
								</CardFrontSix>

								<CardBack>
									<CardBackTitle> Dave Fairbairn-(Co-founder, CEO)</CardBackTitle>
									<CardBackLocation>London, UK via Toronto, Canada</CardBackLocation>
									<CardDestyledription>
										{' '}
										I am obsessed with making highly detailed, beautiful garments. A few years ago I
										built a high end denim brand called Maple (RIP), where I was exposed to some of
										the dodgy fashion industry practices that are purposely keep secret. As a father
										to a gaggle of kids, my role is to lead by example. I choose to use my
										entrepreneurial skills as a force for good, so I can leave the planet better
										than I found it.
									</CardDestyledription>
								</CardBack>
							</Card>
						</GridColumn>
					</GridRow>
				</GridContainer>
			</LayoutWrapper>
		);
	}
}

// Render Layout React element into the DOM
export default AboutCard;

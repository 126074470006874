import React from "react";
import { Container } from "reactstrap";

import Navigation from "../Home/Navigation/Navigation.js";

class Status404NotFound extends React.Component {
  render() {
    // Get the height of the Nav Bar, so we can position the content below it
    const navBar = document.querySelector("header");
    const paddingTop = navBar
      ? (parseInt(navBar.clientHeight) + 10).toString() + "px"
      : "78px";

    return (
      <div>
        <Navigation />
        <Container
          className="mt-0 mb-0 vh-100 d-flex justify-content-center align-items-center"
          style={{ paddingTop: paddingTop }}
        >
          <div className="text-dark text-center">
            <h1>404</h1>
            <h3>The requested page could not be found</h3>
          </div>
        </Container>
      </div>
    );
  }
}

export default Status404NotFound;

import React from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';
import {Link} from "react-router-dom";
export default class NavigationTwo extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	}
	render() {
		return (
			<div style={{marginBottom: '50px'}}>

				<Navbar fixed='top' style={{ backgroundColor: this.props.color, }} className={`"${this.props.mobile}"`}  dark expand="md">
					<NavbarBrand href="/">
						<img className="logo-light " width="80" src="/logo3.png" />
					</NavbarBrand>
					<NavbarToggler onClick={this.toggle} />
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav className="ml-auto" navbar>
							<NavItem>
								<NavLink onClick={this.props.modal}><span className="item">Sign Up</span></NavLink>
							</NavItem>
							<NavItem>
								<NavLink> <Link className="item" to="/about">About</Link> </NavLink>
							</NavItem>
							
							<NavItem>
							<NavLink> <Link className="item" to="/submit_design">Submit a Design</Link></NavLink>
							</NavItem>
							<NavItem>
							   <NavLink> <Link className="item" to="/leaderboard">Leaderboard</Link> </NavLink>	
							</NavItem>
							<NavItem>
							<NavLink> <Link className="item" to="/vote">Vote</Link> </NavLink>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>
				
			</div>
		);
	}
}

import React from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";
import pluralize from "pluralize";

import recycleArrow from "../images/recycle-arrow.svg";

class FundingProgressComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipIsOpen: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  // TODO: Give credit - https://thenounproject.com/linseedstudio/collection/arrows/?i=783916
  render() {
    // TODO: Figure out the more general way of passing props
    const { design } = this.props;
    const percentFunded = parseInt((design.orders / design.ordersGoal) * 100);
    const daysLeft = Math.max(
      parseInt((Date.parse(design.dropEnd) - Date.now()) / 1000 / 60 / 60 / 24),
      0
    );

    return (
      <div id="funding-widget">
        <div className="d-flex justify-content-between text-dark">
          <div>
            <span className="h1">STARTING FROM</span>
            <br />
            <div id="price-change-tooltip">
              <img
                src={recycleArrow}
                height="30"
                alt="Arrows in a circle icon"
              />
              <span className="text-primary"> WILL THIS PRICE CHANGE?</span>
            </div>
            <Tooltip
              isOpen={this.state.tooltipIsOpen}
              target="price-change-tooltip"
              toggle={this.toggleTooltip}
            >
              The price will change when...
            </Tooltip>
          </div>
          <div className="h1">&pound;{design.initialPrice}</div>
        </div>
        <FundingProgressBar design={design} containerId="funding-widget" />
        <div className="d-flex justify-content-between">
          <div className="text-dark">
            Min Order
            <br />
            {percentFunded}% Funded
          </div>
          <div className="text-dark text-right">
            Max Order
            <br />
            {daysLeft} {pluralize("DAYS", daysLeft)} LEFT
          </div>
        </div>
      </div>
    );
  }

  toggleTooltip(event) {
    this.setState({ tooltipIsOpen: !this.state.tooltipIsOpen });
  }
}

// TODO: I think I should get rid of the progress bar spacer. It looks messy.
// TODO: Add components for different types of template and progress bars?
class FundingProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      containerWidthSet: false
    };
  }

  render() {
    // Get the container width in pixels. This is required to make this component responsive. Other
    // widths are calculated based the container width.
    // NOTE: This method takes three renders to allow the child access to the container's width.
    const container = document.getElementById(this.props.containerId);
    this.setState({ containerWidthSet: container ? true : false });
    const containerWidth = container
      ? container.clientWidth
      : dimensions.containerWidth;

    // Get product parameters
    // const design = {
    //   orders: 100,
    //   ordersMax: 100,
    //   ordersGoal: 20,
    //   minimumPrice: 49,
    //   currentPrice: 59
    // };
    // const {
    //   orders,
    //   ordersMax,
    //   ordersGoal,
    //   minimumPrice,
    //   currentPrice
    // } = design;
    const {
      orders,
      ordersMax,
      ordersGoal,
      minimumPrice,
      currentPrice
    } = this.props.design;

    // Get bar dimensions
    const ordersGoalBarWidth = parseInt(
      (ordersGoal / ordersMax) * containerWidth
    );
    const ordersMaxBarWidth = containerWidth - ordersGoalBarWidth;
    const designNotFundedBarWidth = parseInt(
      (Math.min(orders, ordersGoal) / ordersMax) * containerWidth
    );
    const designFundedBarWidth = Math.max(
      parseInt(((orders - ordersGoal) / ordersMax) * containerWidth),
      0
    );

    // Get positions
    const markerLeft = designNotFundedBarWidth + designFundedBarWidth;
    const curPriceLeft = Math.min(
      markerLeft,
      containerWidth - dimensions.curPriceWidth
    );

    const funded = designFundedBarWidth > 0;

    return (
      <Container className="text-dark" width={containerWidth}>
        <TemplateBar backgroundColor="gray" width={ordersGoalBarWidth} />
        <TemplateBar
          backgroundColor="lightgray"
          width={ordersMaxBarWidth}
          left={ordersGoalBarWidth}
        />
        <ProgressBar backgroundColor="gold" width={designNotFundedBarWidth} />
        {funded ? (
          <ProgressBar
            backgroundColor="green"
            width={designFundedBarWidth}
            left={designNotFundedBarWidth}
          />
        ) : null}
        <Marker left={markerLeft} />
        <MinPrice>Min Price &pound;{minimumPrice}</MinPrice>
        <CurPrice left={curPriceLeft}>&pound;{currentPrice}</CurPrice>
      </Container>
    );
  }

  shouldComponentUpdate(nextState, nextProps) {
    if (!this.state.containerWidthSet) {
      return true;
    }
    return false;
  }
}

const dimensions = {
  containerHeight: 90,
  containerWidth: 500,
  templateBarHeight: 10,
  progressBarHeight: 30,
  markerHeight: 60,
  markerWidth: 2,
  curPriceWidth: 40,
  bottomSpacing: 10
};

const Container = styled.div`
  position: relative;
  height: ${asPx(dimensions.containerHeight)};
  width: ${props =>
    props.width ? asPx(props.width) : asPx(dimensions.containerWidth)};
`;

const AbsoluteDiv = styled.div`
  position: absolute;
`;

const MinPrice = styled(AbsoluteDiv)`
  bottom: ${asPx(dimensions.bottomSpacing + dimensions.templateBarHeight)};
  right: 0px;
  z-index: 10;
`;

const CurPrice = styled(AbsoluteDiv)`
  bottom: ${asPx(dimensions.bottomSpacing + dimensions.markerHeight)};
  width: ${asPx(dimensions.curPriceWidth)};
  left: ${props => asPx(props.left)};
`;

const Bar = styled.div`
  position: absolute;
  bottom: ${asPx(dimensions.bottomSpacing)};
  background-color: ${props => props.backgroundColor};
  width: ${props => asPx(props.width)};
  left: ${props => asPx(props.left)};
`;

const TemplateBar = styled(Bar)`
  height: ${asPx(dimensions.templateBarHeight)};
`;

const ProgressBar = styled(Bar)`
  height: ${asPx(dimensions.progressBarHeight)};
`;

const Marker = styled(Bar)`
  height: ${asPx(dimensions.markerHeight)};
  width: ${asPx(dimensions.markerWidth)};
  background-color: black;
`;

function asPx(int) {
  return `${int}px`;
}

export default FundingProgressComponent;

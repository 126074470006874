import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import client from "../../graphql.js";
import { gql } from "apollo-boost";
//import VoteCarouselContent from './VoteCarouselContent/VoteCarouselContent';

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ width: "38px", height: "70px" }}
      onClick={onClick}
      src="/chevron-left-vote.svg"
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, width: "40px", height: "80px", maxWidth: "100%" }}
      onClick={onClick}
      src="/chevron-right-vote.svg"
    />
  );
}

// Helper function to convert pixels to rems
const remy = px => `${px / 16}rem`;

// Function for calculating value for width
const getWidth = value => {
  if (!value) return;

  let width = (value / 12) * 100;
  return `width: ${width}%;`;
};

// Function for calculating value for flex
const getFlex = value => {
  if (!value) return;

  let flex = (value / 12) * 100;
  return `flex: 0 0 ${flex}%;`;
};

// Grid row
const GridRow = styled.div`
  margin-right: ${remy(-15)};
  margin-left: ${remy(-15)};
  display: flex;
  flex-wrap: wrap;
`;

// Grid columns
const GridColumn = styled.div`
  padding-right: ${remy(15)};
  padding-left: ${remy(15)};

  // Columns for mobile
  ${({ xs }) => (xs ? getFlex(xs) : "flex: 0 0 100%")};
  ${({ xs }) => (xs ? getWidth(xs) : "width: 100%")};

  // Columns for tablets
  @media (min-width: 576px) {
    ${({ sm }) => sm && getFlex(sm)};
    ${({ sm }) => sm && getWidth(sm)};
  }

  // Columns for small desktops
  @media (min-width: 768px) {
    ${({ md }) => md && getFlex(md)};
    ${({ md }) => md && getWidth(md)};
  }

  // Columns for medium desktops
  @media (min-width: 992px) {
    ${({ lg }) => lg && getFlex(lg)};
    ${({ lg }) => lg && getWidth(lg)};
  }

  // Columns for large desktops and HD devices
  @media (min-width: 1200px) {
    ${({ xl }) => xl && getFlex(xl)};
    ${({ xl }) => xl && getWidth(xl)};
  }
`;

const MainDiv = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    margin-top: 50px;
    padding: 40px;
    height: 70vh;
    background-color: #fff;
    justify-content: center;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 50px;
    padding: 100px;
    height: 80vh;
    background-color: #fff;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin-top: 50px;
    padding: 100px;
    height: 80vh;
    background-color: #fff;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin-top: 50px;
    padding: 50px;
    height: 80vh;
    background-color: #fff;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    padding: 100px;
    height: 88vh;
    background-color: #fff;
  }
`;

const ThumbsUp = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    text-align: center;
    margin-left: 1vh;
    width: 50px;
    height: 50px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    width: 38px;
    height: 38px;
    margin: 0px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin: 0px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin: 0px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    margin: 0px;
    width: 50px;
    height: 50px;
    margin-left: 7vh;
  }

  @-moz-document url-prefix() {
    // Columns for mobile
    @media screen and (max-width: 576px) {
      text-align: center;
      /*margin-left: -400px;*/
      width: 40px;
      height: 40px;
      margin-top: -11vh;
      margin-left: 26vh;
    }
    /* Portrait iphone 6,7,8 */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
      width: 40px;
      height: 40px;
      margin-top: -17vh;
      margin-left: 31vh;
    }

    /* Portrait iphone6,7,8+*/
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      width: 40px;
      height: 40px;
      margin-top: -11vh;
      margin-left: 30vh;
    }

    // Columns for large desktops and HD devices
    @media screen and (min-width: 1200px) {
      margin: 0px;
      width: 50px;
      height: 50px;
      margin-left: 2vh;
    }
  }
`;

const Dots = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    width: 64px;
    height: 65px;
    text-align: center;
    margin-top: 20px;
    margin-right: 0px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin: 0px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin: 0px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin-top: 15px;
    width: 30px;
    height: 30px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    margin-top: 15px;
    margin-left: 50px;
    width: 50px;
  }
  //FireFox Media Queries
  @-moz-document url-prefix() {
    // Columns for mobile
    @media screen and (max-width: 576px) {
      width: 64px;
      height: 65px;
      text-align: center;
    }
    /* Portrait iphone 6,7,8 */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
      width: 64px;
      height: 65px;
      text-align: center;
    }
    /* Portrait iphone6,7,8+*/
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      width: 64px;
      height: 65px;
      margin-left: 1vh;
      text-align: center;
    }
    // Columns for tablets
    @media screen and (min-width: 576px) and (max-width: 768px) {
      margin: 0px;
    }

    // Columns for small desktops
    @media screen and (min-width: 768px) and (max-width: 992px) {
      margin: 0px;
    }

    // Columns for medium desktops
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      margin-top: 15px;
      width: 30px;
      height: 30px;
    }
    // Columns for large desktops and HD devices
    @media screen and (min-width: 1200px) {
      margin-top: 15px;
      margin-left: 5px;
      width: 80px;
    }
  }
`;

const ThumbsDown = styled.div`
  // Columns for mobile
  @media screen and (max-width: 576px) {
    text-align: center;
    width: 50px;
    height: 50px;
  }

  // Columns for tablets
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin-left: 45px;
  }

  // Columns for small desktops
  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin-left: 45px;
  }

  // Columns for medium desktops
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin-left: 45px;
  }

  // Columns for large desktops and HD devices
  @media screen and (min-width: 1200px) {
    margin-left: 45px;
    width: 50px;
    height: 50px;
  }
  @-moz-document url-prefix() {
    // Columns for mobile
    @media screen and (max-width: 576px) {
      text-align: center;
      /*margin-left: -400px;*/
      width: 42px;
      height: 42px;
      margin-left: 30px;
    }
    // Columns for large desktops and HD devices
    @media screen and (min-width: 1200px) {
      margin-left: 45px;
      width: 50px;
      height: 50px;
    }
  }
`;

export default class VoteCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      card: []
    };
    client
      .query({
        query: gql`
          {
            designs(first: 10, sort: UPVOTES_DESC) {
              edges {
                node {
                  id
                  name
                  image
                }
              }
            }
          }
        `
      })
      .then(result => {
        console.log(result.data.designs.edges);
        this.setState({
          cards: this.shuffle(result.data.designs.edges),
          card: result.data.designs.edges[0].node,
          loading: false
        });
      });
  }

  shuffle = array => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      afterChange: () => this.props.afterChange,
      beforeChange: (current, next) => this.props.beforeChange(next)
    };

    let design = this.state.cards.map(data => data);

    return (
      <MainDiv>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div>
            {/*<Text1 className="textFontFamily2">{data.node.name}</Text1>*/}
            {/*<Text2>DESIGNED BY T.C.S</Text2>*/}
            <img
              style={{ objectFit: "contain", maxWidth: "90%" }}
              src="https://cutso-mvp.s3-eu-west-1.amazonaws.com/designs/red-forest/red-forest-1500-1580.jpg"
            />
            <GridRow>
              <GridColumn xs="4" sm="4" md="3" lg="3" xl="3">
                <ThumbsDown>
                  <Link to="/vote">
                    {" "}
                    <img style={{ maxWidth: "100%" }} src="/tdown.png" />
                  </Link>
                </ThumbsDown>
              </GridColumn>
              <GridColumn xs="4" sm="4" md="3" lg="3" xl="3">
                <Dots>
                  <img style={{ maxWidth: "100%" }} src="/dots2.png" />{" "}
                </Dots>
              </GridColumn>
              <GridColumn xs="4" sm="4" md="3" lg="3" xl="3">
                <ThumbsUp>
                  <Link to="/vote">
                    <img style={{ maxWidth: "100%" }} src="/tup.png" />{" "}
                  </Link>
                </ThumbsUp>
              </GridColumn>
            </GridRow>
          </div>

          {/*this.state.cards.map(data => (
						<div className="row">
							<div className="col-md-3">
								<ThumbsDown>
									<Link to="/vote">
										{' '}
										<img style={{ maxWidth: '100%' }} src="/tdown.png" />
									</Link>
								</ThumbsDown>
							</div>
							<div className="col-md-3">
								<Dots>
									<img style={{ maxWidth: '100%' }} src="/dots2.png" />{' '}
								</Dots>
							</div>
							<div className="col-md-3">
								<ThumbsUp>
									<Link to="/vote">
										<img style={{ maxWidth: '100%' }} src="/tup.png" />{' '}
									</Link>
								</ThumbsUp>
							</div>
						</div>
							<div>
								{/*<Text1 className="textFontFamily2">{data.node.name}</Text1>*
								{/*<Text2>DESIGNED BY T.C.S</Text2>*
								<img
								
									style={{ objectFit: 'contain', maxWidth: '100%' }}
									src={`${data.node.image}/${data.node.name}-1500-1580.jpg`}
								/>
							</div>
						</div>
					))<GridRow>
							<GridColumn xs="3" sm="3" md="3" lg="3" xl="3">
								<ThumbsDown>
									<Link to="/vote">
										{' '}
										<img style={{ maxWidth: '100%' }} src="/tdown.png" />
									</Link>
								</ThumbsDown>
							</GridColumn>
							<GridColumn xs="3" sm="3" md="3" lg="3" xl="3">
								<Dots>
									<img style={{ maxWidth: '100%' }} src="/dots2.png" />{' '}
								</Dots>
							</GridColumn>
							<GridColumn xs="3" sm="3" md="3" lg="3" xl="3">
								<ThumbsUp>
									<Link to="/vote">
										<img style={{ maxWidth: '100%' }} src="/tup.png" />{' '}
									</Link>
								</ThumbsUp>
							</GridColumn>
						</GridRow>
					*/}
        </Slider>
      </MainDiv>
    );
  }
}

import React from "react";
import CountdownTimer from "react-component-countdown-timer";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";

import client from "../graphql.js";
import "./DropTimer.css";

class DropTimer extends React.Component {
  render() {
    const query = gql`
      {
        drops(sort: VOTE_START_ASC) {
          edges {
            node {
              voteStart
              voteEnd
              orderStart
              orderEnd
            }
          }
        }
      }
    `;
    return (
      <Query query={query} client={client}>
        {({ loading, error, data }) => {
          if (loading) {
            return "";
          }
          if (error) {
            return "";
          }

          // TODO - Drops should be filterable in the GraphQL API
          // Get current drop
          var drop = {};
          for (const edge of data.drops.edges) {
            if (isInTheFuture(edge.node.orderEnd)) {
              drop = edge.node;
              break;
            }
          }

          var count = 0;
          var label = "";
          if (isInTheFuture(drop.voteStart)) {
            label = "Voting will start in:";
            count = getSecondsUntil(drop.voteStart);
          } else if (
            !isInTheFuture(drop.voteStart) &&
            isInTheFuture(drop.voteEnd)
          ) {
            label = "Next drop in:"; // TODO: change if voteEnd != order start
            count = getSecondsUntil(drop.voteEnd);
          } else if (
            !isInTheFuture(drop.voteEnd) &&
            isInTheFuture(drop.orderStart)
          ) {
            label = "Next drop in:";
            count = getSecondsUntil(drop.orderStart);
          } else if (
            !isInTheFuture(drop.orderStart) &&
            isInTheFuture(drop.orderEnd)
          ) {
            label = "Ordering ends in:";
            count = getSecondsUntil(drop.orderEnd);
          } else {
            label = "Ordering has ended";
            count = 0;
          }

          return (
            <div className="d-flex flex-column">
              <p className="align-self-center mb-0 display-5">{label}</p>
              <CountdownTimer
                className={this.props.className}
                backgroundColor="transparent"
                color="#fff"
                count={count}
                size={48}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

function isInTheFuture(date) {
  return getSecondsUntil(date) > 0;
}
function getSecondsUntil(date) {
  return (Date.parse(date) - Date.now()) / 1000;
}

export default DropTimer;
